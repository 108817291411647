import Vue from "vue";
import VueRouter from "vue-router";

import StakePulseDogecoin from "../page/StakePulseDogecoin.vue";
import CommunityCarnivalAsicMiner from "../page/CommunityCarnivalAsicMiner.vue";
import PulseBitcoinLockApp from "../page/PulseBitcoinLockApp.vue";
import FreeCarn from "../page/FreeCarn.vue";
import FreeHex from "../page/FreeHex";
import CarnTicketBooth from "../page/CarnTicketBooth.vue";
import BuyAndBurn from "../page/BuyAndBurn.vue";
import Lotteries from "../page/Lotteries.vue";
import LotteriesHour from "../page/LotteriesHour.vue";
import LotteriesWeek from "../page/LotteriesWeek.vue";
import LotteriesMonth from "../page/LotteriesMonth.vue";
import WAATCA from "../page/WAATCA.vue";
import WhitePaper from "../page/WhitePaper.vue";
import Faq from "../page/Faq.vue";
import Disclaimer from "../page/Disclaimer.vue";
import Hex from "../page/Hex.vue";
import PulseBitcoinFaucet from "../page/PulseBitcoinFaucet.vue"
Vue.use(VueRouter);

const routes = [
  {
    path: "/waatca",
    name: "WAATCA",
    component: WAATCA,
  },
  {
    path: "/stake_pulsedogecoin",
    name: "StakePulseDogecoin",
    component: StakePulseDogecoin,
  },
  {
    path: "/community_asic_miner",
    name: "CommunityCarnivalAsicMiner",
    component: CommunityCarnivalAsicMiner,
  },
  {
    path: "/pulse_bitcoin_lock_app_secret",
    name: "PulseBitcoinLockApp",
    component: PulseBitcoinLockApp,

  },
  {
    path: "/lotteries",
    name: "Lotteries",
    component: Lotteries,
    redirect: { name: 'LotteriesMonth' },
    children: [
      {
        path: "daily_secret",
        name: "LotteriesHour",
        component: LotteriesHour,
      },
      {
        path: "weekly_secret",
        name: "LotteriesWeek",
        component: LotteriesWeek,
      },
      {
        path: "monthly",
        name: "LotteriesMonth",
        component: LotteriesMonth,
      },


    ]
  },
  {
    path: "/carn_rewards",
    name: "FreeCarn",
    component: FreeCarn,
  },
  {
    path: "/hex_rewards",
    name: "FreeHex",
    component: FreeHex,
  },
  {
    path: "/carn_token_booth",
    name: "CarnTicketBooth",
    component: CarnTicketBooth,
  },
  {
    path: "/buy_n_burn",
    name: "BuyAndBurn",
    component: BuyAndBurn,
  },
  {
    path: "/",
    name: "Hex",
    component: Hex,
  },


  {
    path: "/white_paper",
    name: "WhitePaper",
    component: WhitePaper,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/disclaimer",
    name: "Disclaimer",
    component: Disclaimer,
  },
  {
    path: "/faucets_secret",
    name: "PulseBitcoinFaucet",
    component: PulseBitcoinFaucet,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
