const actions = {
  SET_LOADING({ commit }, payload) {
    commit("SET_LOADING", payload);
  },
  SET_WALLETCHAINID({ commit }, payload) {
    commit("SET_WALLETCHAINID", payload);
  },
  SET_WEB3({ commit }, payload) {
    commit("SET_WEB3", payload);
  },
  SET_USER_ADDRESS({ commit }, payload) {
    commit("SET_USER_ADDRESS", payload);
  },
  SET_CARN_INSTANCE({ commit }, payload) {
    commit("SET_CARN_INSTANCE", payload);
  },
  SET_PLSD_INSTANCE({ commit }, payload) {
    commit("SET_PLSD_INSTANCE", payload);
  },
  SET_PLSB_INSTANCE({ commit }, payload) {
    commit("SET_PLSB_INSTANCE", payload);
  },
  SET_ASIC_INSTANCE({ commit }, payload) {
    commit("SET_ASIC_INSTANCE", payload);
  },

  SET_USDC_INSTANCE({ commit }, payload) {
    commit("SET_USDC_INSTANCE", payload);
  },
  SET_HEX_INSTANCE({ commit }, payload) {
    commit("SET_HEX_INSTANCE", payload);
  },
  SET_CHAINLINK_INSTANCE({ commit }, payload) {
    commit("SET_CHAINLINK_INSTANCE", payload);
  },
  SET_STAKING_INSTANCE({ commit }, payload) {
    commit("SET_STAKING_INSTANCE", payload);
  },
  SET_WAATCA_INSTANCE({ commit }, payload) {
    commit("SET_WAATCA_INSTANCE", payload);
  },
  SET_NFT_REWARDS_INSTANCE({ commit }, payload) {
    commit("SET_NFT_REWARDS_INSTANCE", payload);
  },
  SET_NFT_HEX_REWARDS_INSTANCE({ commit }, payload) {
    commit("SET_NFT_HEX_REWARDS_INSTANCE", payload);
  },
  SET_BURN_INSTANCE({ commit }, payload) {
    commit("SET_BURN_INSTANCE", payload);
  },
  SET_ASIC_MINER_INSTANCE({ commit }, payload) {
    commit("SET_ASIC_MINER_INSTANCE", payload);
  },
  SET_HEX_STAKER_INSTANCE({ commit }, payload) {
    commit("SET_HEX_STAKER_INSTANCE", payload);
  },
  SET_CARN_FAUCET_INSTANCE({ commit }, payload) {
    commit("SET_CARN_FAUCET_INSTANCE", payload);
  },
  SET_PLSD_FAUCET_INSTANCE({ commit }, payload) {
    commit("SET_PLSD_FAUCET_INSTANCE", payload);
  },
  SET_PLSB_FAUCET_INSTANCE({ commit }, payload) {
    commit("SET_PLSB_FAUCET_INSTANCE", payload);
  },
  SET_ASIC_FAUCET_INSTANCE({ commit }, payload) {
    commit("SET_ASIC_FAUCET_INSTANCE", payload);
  },

  SET_USDC_FAUCET_INSTANCE({ commit }, payload) {
    commit("SET_USDC_FAUCET_INSTANCE", payload);
  },
  SET_HEX_FAUCET_INSTANCE({ commit }, payload) {
    commit("SET_HEX_FAUCET_INSTANCE", payload);
  },
  SET_TOKEN_INSTANCE({ commit }, payload) {
    commit("SET_TOKEN_INSTANCE", payload);
  },
  SET_CONTRACT_INSTANCE({ commit }, payload) {
    commit("SET_CONTRACT_INSTANCE", payload);
  },
  
  
};

export default actions;
