<template>
  <div>
    <v-row class="justify-center">
      <v-col>
        <v-card class="mx-auto" @click="addToken('PLSD')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="cyan lighten-1">
              <img src="@/assets/plsdlogov2-modified.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(PLSDBalance) }}  $PLSD
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto" @click="addToken('PLSB')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="blue">
              <img src="@/assets/plsblogo-modified.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(PLSBBalance) }}  $PLSB
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto" @click="addToken('ASIC')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="purple darken-2">
              <img src="@/assets/asiclogo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(ASICBalance) }}  $ASIC
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="green">
              <img src="@/assets/carn_logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(CARNBalance) }}  $CARN
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto" @click="addToken('HEX')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="green">
              <img src="@/assets/hex.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ eightFormat(HEXBalance) }}  $HEX
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="getUserAddress" class="justify-center">
      <div class="col-12 col-sm-6 col-md-5">
        <v-card class="mx-auto text-center">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="demo">0 Days 0 Hours 0 Minutes 0 Seconds</h3>
              </v-list-item-title>
              <h4 class="font-weight-regular">
                until the {{ text }} Period is Over.
              </h4>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>
    <v-row>
      <div class="col-12 col-md-6">
        <v-card
          :class="this.state === 0 ? '' : 'bg-grey'"
          class="pa-3 pa-sm-5"
          style="height: 100%"
        >
          <h1 class="grey--text text--darken-2">10 Day Reload Period</h1>
          <h4 class="font-weight-regular">
            10 days to withdraw principal + rewards and then stake again
          </h4>
          <v-divider class="my-5"></v-divider>
          <v-card outlined class="pa-5 my-5">
            <h3 class="font-weight-regular">
              Withdraw Principal + Rewards from the Last Staking Period
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <img
                    width="18px"
                    src="@/assets/info.webp"
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <span>
                Rewards come from all the different parts of the Carnival including the Community ASIC Miner, the Lotteries, Penalties from Emergency End Staking and Not Withdrawing rewards on time etc.
              </span>
            </v-tooltip>
            </h3>


            <v-btn
              @click="onClaimRewards"
              block
              x-large
              color="yellow"
              class="py-5 mt-5"
              :disabled="
                !getUserAddress ||
                isLoading ||
                Number(stakes.stakeId) === currentStakeId ||
                !Number(stakes.amount)
              "
            >
              Withdraw
            </v-btn>
            <!--            <small>Note: You must withdraw in the reload period, or else your principal and rewards will go into the next rewards pool and split amongst the next set of stakers</small>-->
          </v-card>
          <v-card outlined class="pa-5 my-5">
            <v-text-field
              outlined
              type="text"
              v-model="stakeAmount"
              label="Enter Stake Amount"
            >
              <template v-slot:append>
                <v-btn
                  text
                  color="primary"
                  @click="stakeAmount = twelveFormat(PLSDBalance,12)"
                  small
                >
                  MAX
                </v-btn>
              </template>
            </v-text-field>
            <v-btn
              @click="onPLSDApprove(true)"
              block
              x-large
              color="yellow"
              class="py-5"
              :disabled="
                !getUserAddress || isLoading || !!Number(stakes.amount)
              "
            >
              {{ isPLSDApproved ?
                    (isCARNApproved ?
                      (Number(stakes.stakeId) === currentStakeId && stakes.amount > 0 ? `You Staked: ${twelveFormat(stakes.amount)} $PLSD` : "Stake")
                    : "Approve CARN")
                : "Approve PLSD" }}
            </v-btn>
            <small>30 CARN PER RIDE</small>
          </v-card>

        </v-card>
      </div>
      <div class="col-12 col-md-6">
        <v-card
          :class="this.state === 1 ? '' : 'bg-grey'"
          class="pa-3 pa-sm-5"
          style="height: 100%"
        >
          <h1 class="grey--text text--darken-2">90 Day Staked Period</h1>
          <h4 class="font-weight-regular">
            90 day period where your $PLSD is staked.
          </h4>
          <v-divider class="my-6"></v-divider>
          <h3 class="my-5">
            You Staked:
            {{ Number(stakes.stakeId) > currentStakeId - 2 ? twelveFormat(stakes.amount) : 0 }} PLSD
          </h3>
          <h3 class="my-5">
            Total Staked: {{ twelveFormat(totalStaked) }} PLSD
          </h3><!--totalStaked-->

          <h3 class="my-5">
            Pool Ownership:
            {{
              Number(totalStaked)
                ? (
                    (Number(stakes.stakeId) === currentStakeId - 1
                      ? Number(stakes.amount)
                      : 0) / Number(totalStaked)
                  ).toFixed(5) * 100
                : 0
            }}% (This field will populate once stake begins)
          </h3>
          <h3 class="my-4">
            Total Pool PLSD Rewards: {{ twelveFormat(plsdRewardPoolTotal) }} $PLSD
          </h3>
          <h3 class="my-3">
            Total Pool PLSB Rewards: {{ twelveFormat(plsbRewardPoolTotal) }} $PLSB
          </h3>
          <h3 class="my-3">
            Total Pool ASIC Rewards: {{ twelveFormat(asicRewardPoolTotal) }} $ASIC
          </h3>
          <h3 class="my-3">
            Total Pool HEX Rewards: {{ eightFormat(hexRewardPoolTotal) }} $HEX
          </h3>
          <v-btn
            @click="onEmergency"
            block
            x-large
            color="red white--text"
            class="py-5"
            :disabled="
              !getUserAddress ||
              isLoading ||
              !Number(stakes.amount) ||
              state === 0
            "
          >
            Emergency End Stake and Withdraw<br />
            75% Principal and No Rewards
          </v-btn>


        </v-card>
      </div>
    </v-row>



    <v-row>
      <div class="col-12 col-sm-6 col-md-6">
        <v-card class="mx-auto" >
          <v-btn
              @click="onLockingPeriod"
              block
              x-large
              color="secondary"
              :disabled="
            !getUserAddress || isLoading || !showStartReloadPeriodButton
          "
          >
            Start Reload Period
          </v-btn>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <v-card class="mx-auto">
          <v-btn
              @click="onLockedPeriod"
              block
              x-large
              color="secondary"
              :disabled="
            !getUserAddress || isLoading || !showStartStakedPeriodButton
          "
          >
            Start Staked Period
          </v-btn>
        </v-card>
      </div>
    </v-row>

    <v-row>
      <div class="col-12">
        <v-card outlined class="pa-5">
          <h1 class="grey--text text--darken-2">
            Reward Pool Donations
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <img
                  width="18px"
                  src="@/assets/info.webp"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>The following 4 functions allow anyone to "Donate" some PLSD, PLSB, ASIC or HEX to the staking rewards pool.
                <br>If the "Donation" is made during the reload period,then the amount will be "Pending" until the Staked Period starts at which point,
                all "Pending" donations will be transferred to the reward pool.
                <br> These are the same 4 functions that the Lottery and Community ASIC Miner use to give some of their revenue towards the PLSD Staking Rewards Pool.
                <br> Do Not send any coins directly to the contract address itself as they will be lost forever!</span>
            </v-tooltip>
          </h1>
          The following 4 functions allow anyone to Benevolently DONATE some PLSD, PLSB, ASIC and HEX to the staking rewards pool.
          <v-divider class="my-5"></v-divider>
          <v-row>
            <div class="col-12 col-md-3">
              <label for=""
                >PLSD Pending Reward:
                {{ twelveFormat(plsdPendingRewards) }}</label
              >
              <v-text-field
                v-model="PLSDDepositAmount"
                outlined
                class="mt-3"
                label="Enter PLSD Amount"
                type="text"
              >
                <template v-slot:append>
                  <v-btn
                    text
                    color="primary"
                    @click="PLSDDepositAmount = twelveFormat(PLSDBalance,12)"
                    small
                  >
                    MAX
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                @click="onPLSDApprove(false)"
                block
                x-large
                color="yellow"
                class="py-5"
                :disabled="!getUserAddress || isLoading"
              >
                {{ isPLSDApproved ? "Donate PLSD" : "Approve PLSD" }}
              </v-btn>
              <v-divider class="d-block d-sm-none mt-6"></v-divider>
            </div>
            <div class="col-12 col-md-3">
              <label for=""
                >PLSB Pending Reward:
                {{ twelveFormat(plsbPendingRewards) }}</label
              >
              <v-text-field
                v-model="PLSBDepositAmount"
                outlined
                class="mt-3"
                label="Enter PLSB Amount"
                type="text"
              >
                <template v-slot:append>
                  <v-btn
                    text
                    color="primary"
                    @click="PLSBDepositAmount = twelveFormat(PLSBBalance,12)"
                    small
                  >
                    MAX
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                @click="onPLSBApprove"
                block
                x-large
                color="yellow"
                class="py-5"
                :disabled="!getUserAddress || isLoading"
              >
                {{ isPLSBApproved ? "Donate PLSB" : "Approve PLSB" }}
              </v-btn>
              <v-divider class="d-block d-sm-none mt-6"></v-divider>
            </div>
            <div class="col-12 col-md-3">
              <label for=""
                >ASIC Pending Reward:
                {{ twelveFormat(asicPendingRewards) }}</label
              >
              <v-text-field
                v-model="ASICDepositAmount"
                outlined
                class="mt-3"
                label="Enter ASIC Amount"
                type="text"
              >
                <template v-slot:append>
                  <v-btn
                    text
                    color="primary"
                    @click="ASICDepositAmount = twelveFormat(ASICBalance,12)"
                    small
                  >
                    MAX
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                @click="onASICApprove"
                block
                x-large
                color="yellow"
                class="py-5"
                :disabled="!getUserAddress || isLoading"
              >
                {{ isASICApproved ? "Donate ASIC" : "Approve ASIC" }}
              </v-btn>
            </div>
            <div class="col-12 col-md-3">
              <label for=""
              >HEX Pending Reward:
                {{ eightFormat(hexPendingRewards) }}</label
              >
              <v-text-field
                  v-model="HEXDepositAmount"
                  outlined
                  class="mt-3"
                  label="Enter HEX Amount"
                  type="text"
              >
                <template v-slot:append>
                  <v-btn
                      text
                      color="primary"
                      @click="HEXDepositAmount = eightFormat(HEXBalance,8)"
                      small
                  >
                    MAX
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                  @click="onHEXApprove"
                  block
                  x-large
                  color="yellow"
                  class="py-5"
                  :disabled="!getUserAddress || isLoading"
              >
                {{ isHEXApproved ? "Donate HEX" : "Approve HEX" }}
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </div>
    </v-row>
    <v-row>
      <div class="col-12">
        <v-card class="pa-5">
          <h1>Staking Instructions</h1>
          <v-divider class="my-2"></v-divider>
          <ol>
            <li>
              <strong> Approve the smart contract to interact with your PLSD and CARN</strong>
            </li>
            <li>
              <strong> During a Reload Period, Choose how much PLSD you want to stake and enter that number into the "Enter Stake Amount" field, then click Stake</strong>
            </li>
            <li>
              <strong> Upon staking, 30 CARN will be withdrawn from your wallet also, so make sure to have at least 30 CARN in you wallet before staking</strong>
            </li>
            <li>
              <strong> You can only stake 1 time per wallet</strong>
            </li>
            <li>
              <strong> A normal reload period is 10 days, but the initial reload period will be 30 days</strong>
            </li>
            <li>
              <strong> Once the Reload Period Ends, anyone can click the START STAKED PERIOD button, and like wise once the Staker Period ends, anyone can click the START RELOAD PERIOD button</strong>
            </li>
            <li>
              <strong> Once a staked period ends, and the 10 day reload period begins, you must withdraw your principal PLSD and PLSD, PLSB, ASIC and HEX rewards in that 10 days or you lose all your principal and rewards</strong>
            </li>
            <li>
              <strong> After you withdraw your principal and rewards, you can stake again for the next 90 day period if you want.</strong>
            </li>
            <li>
              <strong> During the 90 day staking period you can Emergency End Stake if want and get 75% of your principal and NO REWARDS</strong>
            </li>
            <li>
              <strong> The purpose of the  "Reward Pool Donations" section above is for benevolent whales - if they choose to - deposit coins using those functions to increase rewards for stakers. (Behind the scenes those same 4 functions are being utilized by the ASIC community Miner, the Hex Community Staker and the Lotteries to distribute a portion of their revenues to the PLSD stakers.</strong>
            </li>
          </ol>

        </v-card>
      </div>
    </v-row>

  </div>
</template>
<script>
export default {
  name: "StakePulseDogecoin",
  data() {
    return {
      text: "Reload",
      clearIntervalX: null,
      stakeAmount: 0,
      
      reloadStart: 0,
      reloadEnd: 0,
      state: 0,
      isCARNApproved: false,
      isPLSDApproved: false,
      isPLSBApproved: false,
      isASICApproved: false,
      isHEXApproved: false,

      CARNBalance: 0,
      PLSDBalance: 0,
      PLSBBalance: 0,
      ASICBalance: 0,
      HEXBalance: 0,

      currentStakeId: 0,
      totalStaked: 0,
      stakes: 0,
      PLSDDepositAmount: 0,
      PLSBDepositAmount: 0,
      ASICDepositAmount: 0,
      HEXDepositAmount: 0,
      plsdPendingRewards: 0,
      plsbPendingRewards: 0,
      asicPendingRewards: 0,
      hexPendingRewards: 0,
      plsdRewardPoolTotal: 0,
      plsbRewardPoolTotal: 0,
      asicRewardPoolTotal: 0,
      hexRewardPoolTotal: 0,
      PLSDContractBalance:0,
    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalX);
  },
  async mounted() {
    if (this.getUserAddress) this.readValues();
  },
  methods: {
    readValues() {
      let address = this.getUserAddress;
      Promise.all([
        this.getCARNInstance.methods.allowance(address, this.STAKING_ADDRESS).call(),
        this.getPLSDInstance.methods.allowance(address, this.STAKING_ADDRESS).call(),
        this.getPLSBInstance.methods.allowance(address, this.STAKING_ADDRESS).call(),
        this.getASICInstance.methods.allowance(address, this.STAKING_ADDRESS).call(),
        this.getHEXInstance.methods.allowance(address, this.STAKING_ADDRESS).call(),
        this.getPLSDInstance.methods.balanceOf(address).call(),
        this.getPLSBInstance.methods.balanceOf(address).call(),
        this.getCARNInstance.methods.balanceOf(address).call(),
        this.getASICInstance.methods.balanceOf(address).call(),
        this.getHEXInstance.methods.balanceOf(address).call(),
        this.getStakingInstance.methods.currentStakeId().call(),
        this.getStakingInstance.methods.stakes(address).call(),
        this.getStakingInstance.methods.plsdRewardPoolTotal().call(),
        this.getStakingInstance.methods.plsbRewardPoolTotal().call(),
        this.getStakingInstance.methods.asicRewardPoolTotal().call(),
        this.getStakingInstance.methods.hexRewardPoolTotal().call(),
        this.getStakingInstance.methods.plsdPendingRewards().call(),
        this.getStakingInstance.methods.plsbPendingRewards().call(),
        this.getStakingInstance.methods.asicPendingRewards().call(),
        this.getStakingInstance.methods.hexPendingRewards().call(),
        this.getStakingInstance.methods.reloadStart().call(),
        this.getStakingInstance.methods.reloadEnd().call(),
        this.getStakingInstance.methods.state().call(),
        this.getPLSDInstance.methods.balanceOf(this.STAKING_ADDRESS).call(),

      ]).then(
        async ([
          isCARNApproved,
          isPLSDApproved,
          isPLSBApproved,
          isASICApproved,
          isHEXApproved,
          PLSDBalance,
          PLSBBalance,
          CARNBalance,
          ASICBalance,
          HEXBalance,
          currentStakeId,
          stakes,
          plsdRewardPoolTotal,
          plsbRewardPoolTotal,
                 asicRewardPoolTotal,
                 hexRewardPoolTotal,
          plsdPendingRewards,
          plsbPendingRewards,
                 asicPendingRewards,
                 hexPendingRewards,
          reloadStart,
          reloadEnd,
          state,
            PLSDContractBalance
        ]) => {
          console.log("stakes:", stakes);
          console.log("reloadEnd:", reloadEnd);
          console.log("reloadStart:", reloadStart);
          console.log("state:", state);
          console.log("currentStakeId:", currentStakeId);
          console.log("isCARNApproved:", !!isCARNApproved);
          console.log("isPLSDApproved:", !!isPLSDApproved);
          console.log("isPLSBApproved:", !!isPLSBApproved);
          console.log("isASICApproved:", !!isASICApproved);
          console.log("isHEXApproved:", !!isHEXApproved);
          console.log("PLSDBalance:", this.twelveFormat(PLSDBalance));
          console.log("PLSBBalance:", this.twelveFormat(PLSBBalance));
          console.log("CARNBalance:", this.twelveFormat(CARNBalance));
          console.log("ASICBalance:", this.twelveFormat(ASICBalance));
          console.log("HEXBalance:", this.twelveFormat(HEXBalance));
          console.log("plsdRewardPoolTotal:", plsdRewardPoolTotal);
          console.log("plsbRewardPoolTotal:", plsbRewardPoolTotal);
          console.log("asicRewardPoolTotal:", asicRewardPoolTotal);
          console.log("hexRewardPoolTotal:", hexRewardPoolTotal);
          console.log("plsdPendingRewards:", plsdPendingRewards);
          console.log("plsbPendingRewards:", plsbPendingRewards);
          console.log("asicPendingRewards:", asicPendingRewards);
          console.log("hexPendingRewards:", hexPendingRewards);
          console.log("PLSDContractBalance:", PLSDContractBalance);
          this.isCARNApproved = isCARNApproved > 0 ? true : false;
          this.isPLSDApproved = isPLSDApproved > 0 ? true : false;
          this.isPLSBApproved = isPLSBApproved > 0 ? true : false;
          this.isASICApproved = isASICApproved > 0 ? true : false;
          this.isHEXApproved = isHEXApproved > 0 ? true : false;
          this.currentStakeId = Number(currentStakeId);
          this.CARNBalance = CARNBalance;
          this.PLSDBalance = PLSDBalance;
          this.PLSBBalance = PLSBBalance;
          this.ASICBalance = ASICBalance;
          this.HEXBalance = HEXBalance;
          this.stakes = stakes;

          this.plsdRewardPoolTotal = plsdRewardPoolTotal;
          this.plsbRewardPoolTotal = plsbRewardPoolTotal;
          this.asicRewardPoolTotal = asicRewardPoolTotal;
          this.hexRewardPoolTotal = hexRewardPoolTotal;

          this.plsdPendingRewards = plsdPendingRewards;
          this.plsbPendingRewards = plsbPendingRewards;
          this.asicPendingRewards = asicPendingRewards;
          this.hexPendingRewards = hexPendingRewards;

          this.totalStaked = await this.getStakingInstance.methods
            .stakePool(this.currentStakeId - 1)
            .call();
          console.log("totalStaked:", this.totalStaked);
          this.reloadStart = Number(reloadStart);
          this.reloadEnd = Number(reloadEnd);
          this.state = Number(state);
          this.PLSDContractBalance = PLSDContractBalance;
          clearInterval(this.clearIntervalX);
          this.counter();
        }
      );
    },
    counter() {
      let countDownDate = 0;

      if (this.state === 0) {
        this.text = "Reload";
        countDownDate = this.reloadEnd * 1000;
        console.log("here state is 0");
      } else if (this.state === 1) {
        this.text = "Staked";
        countDownDate = this.reloadStart * 1000;
        console.log("here state is 1");
      }

      this.clearIntervalX = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now + 20000;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalX);
          document.getElementById(
            "demo"
          ).innerHTML = `0 Days 0 Hours 0 Minutes 0 Seconds`;
        } else {
          document.getElementById(
            "demo"
          ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },

    // PLSD
    onPLSDApprove(isStaking) {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }

      if (this.isPLSDApproved) {
        if (isStaking)
          if (this.isCARNApproved) this.onStake();
          else this.onCARNApprove();
        else this.onPLSDDeposit();
        return;
      }
      this.SET_LOADING(true);
      this.getPLSDInstance.methods
        .approve(this.STAKING_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("PLSD Approve transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("PLSD Approve transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("PLSD Approve transaction has Failed");
        });
    },
    onCARNApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getCARNInstance.methods
        .approve(this.STAKING_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("CARN Approve transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("CARN Approve transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("CARN Approve transaction has Failed");
        });
    },
    onStake() {
      if (!this.stakeAmount) {
        this.$toasted.show("Enter PLSD Staking Amount");
        return;
      }
      if (Number(this.twelveFormat(this.CARNBalance)) < 30) {
        this.$toasted.show(
          "You Need at least 30 CARN in your account to Stake"
        );
        return;
      }
      if (
        Number(this.stakeAmount) > Number(this.twelveFormat(this.PLSDBalance, 12))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }
      this.SET_LOADING(true);
      this.getStakingInstance.methods
        .stake(this.twelveToWei(this.stakeAmount))
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Staking transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.clearForm();
          this.SET_LOADING(false);
          this.$toasted.show("Staking transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Staking transaction has Failed");
        });
    },
    onPLSDDeposit() {
      if (!this.PLSDDepositAmount) {
        this.$toasted.show("Enter PLSD Deposit Amount");
        return;
      }
      if (
        Number(this.PLSDDepositAmount) >
        Number(this.twelveFormat(this.PLSDBalance))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }

      this.SET_LOADING(true);
      this.getStakingInstance.methods
        .depositPLSD(this.twelveToWei(this.PLSDDepositAmount))
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("PLSD Deposit transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.clearForm();
          this.SET_LOADING(false);
          this.$toasted.show("PLSD Deposit transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("PLSD Deposit transaction has Failed");
        });
    },

    // PLSB
    onPLSBApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      if (this.isPLSBApproved) {
        this.onPLSBDeposit();
        return;
      }
      this.SET_LOADING(true);
      this.getPLSBInstance.methods
        .approve(this.STAKING_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("PLSB Approve transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("PLSB Approve transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("PLSB Approve transaction has Failed");
        });
    },
    onPLSBDeposit() {
      if (!this.PLSBDepositAmount) {
        this.$toasted.show("Enter PLSB Deposit Amount");
        return;
      }
      if (
        Number(this.PLSBDepositAmount) >
        Number(this.twelveFormat(this.PLSBBalance))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }
      this.SET_LOADING(true);
      this.getStakingInstance.methods
        .depositPLSB(this.twelveToWei(this.PLSBDepositAmount))
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("PLSB Deposit transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.clearForm();
          this.SET_LOADING(false);
          this.$toasted.show("PLSB Deposit transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("PLSB Deposit transaction has Failed");
        });
    },

    // ASIC
    onASICApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      if (this.isASICApproved) {
        this.onASICDeposit();
        return;
      }
      this.SET_LOADING(true);
      this.getASICInstance.methods
        .approve(this.STAKING_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("ASIC Approve transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("ASIC Approve transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("ASIC Approve transaction has Failed");
        });
    },
    onASICDeposit() {
      if (!this.ASICDepositAmount) {
        this.$toasted.show("Enter ASIC Deposit Amount");
        return;
      }
      if (
        Number(this.ASICDepositAmount) >
        Number(this.twelveFormat(this.ASICBalance))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }
      this.SET_LOADING(true);
      this.getStakingInstance.methods
        .depositASIC(this.twelveToWei(this.ASICDepositAmount))
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("ASIC Deposit transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.clearForm();
          this.SET_LOADING(false);
          this.$toasted.show("ASIC Deposit transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("ASIC Deposit transaction has Failed");
        });
    },

    // HEX
    onHEXApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      if (this.isHEXApproved) {
        this.onHEXDeposit();
        return;
      }
      this.SET_LOADING(true);
      this.getHEXInstance.methods
          .approve(this.STAKING_ADDRESS, "1000000000000000000000000000")
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("HEX Approve transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("HEX Approve transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("HEX Approve transaction has Failed");
          });
    },
    onHEXDeposit() {
      if (!this.HEXDepositAmount) {
        this.$toasted.show("Enter HEX Deposit Amount");
        return;
      }
      if (
          Number(this.HEXDepositAmount) >
          Number(this.eightFormat(this.HEXBalance))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }
      this.SET_LOADING(true);
      this.getStakingInstance.methods
          .depositHEX(this.eightToWei(this.HEXDepositAmount))
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("HEX Deposit transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.clearForm();
            this.SET_LOADING(false);
            this.$toasted.show("HEX Deposit transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("HEX Deposit transaction has Failed");
          });
    },


    onClaimRewards() {
      this.SET_LOADING(true);
      this.getStakingInstance.methods
        .claimRewards()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Claim Rewards transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Claim Rewards transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Claim Rewards transaction has Failed");
        });
    },
    onEmergency() {
      this.SET_LOADING(true);
      this.getStakingInstance.methods
        .emergencyEnd()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Emergency end transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Emergency end transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Emergency end transaction has Failed");
        });
    },
    onLockingPeriod() {
      this.SET_LOADING(true);
      this.getStakingInstance.methods
        .startReloadPeriod()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Start Reload Period transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Start Reload Period transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Start Reload Period transaction has Failed");
        });
    },
    onLockedPeriod() {
      this.SET_LOADING(true);
      this.getStakingInstance.methods
        .startLockedPeriod()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Start Locked transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Start Locked transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Start Locked transaction has Failed");
        });
    },

    clearForm() {
      this.stakeAmount = 0;
      this.PLSDDepositAmount = 0;
      this.PLSBDepositAmount = 0;
      this.ASICDepositAmount = 0;
    },
  },
  computed: {
    showStartReloadPeriodButton() {
      //1676252220
      return (
        this.state === 1 && new Date().getTime() > this.reloadStart * 1000
      );
    },
    showStartStakedPeriodButton() {
      return this.state === 0 && new Date().getTime() > this.reloadEnd * 1000;
    },
  },
  watch: {
    getUserAddress() {
        if (this.getUserAddress)
          this.readValues();
    },
  },
};
</script>
<style>
.v-main.StakePulseDogecoin .v-main__wrap {
  background-image: url("../assets/hexlogos2.jpg");

  background-size: 200%;
  background-position: center;
}

</style>
