<template>
  <div>
    <v-row class="justify-center">
      <template v-for="(lottery, i) in lotteries">
        <div class="col-12 col-sm-4 col-md-2" :key="i">
          <v-card class="mx-auto" @click="addToken(lottery.token)">
            <v-list-item two-line>
              <v-list-item-avatar size="55" color="cyan lighten-1">
                <img
                    v-if="lottery.token === 'PLSD'"
                    src="@/assets/plsdlogov2-modified.png"
                />
                <img
                    v-if="lottery.token === 'PLSB'"
                    src="@/assets/plsblogo-modified.png"
                />
                <img
                    v-if="lottery.token === 'ASIC'"
                    src="@/assets/asiclogo.png"
                />
                <img
                    v-if="lottery.token === 'CARN'"
                    src="@/assets/carn_logo.png"
                />
                <img v-if="lottery.token === 'USDC'" src="@/assets/usdc.png" />
                <img v-if="lottery.token === 'HEX'" src="@/assets/hex.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h7 mb-1">
                  {{ generalFormat(lottery.balance, lottery.decimals) }} ${{lottery.token }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </template>
    </v-row>
    <v-row>
      <template v-for="(lottery, i) in lotteries">
        <div class="col-12 col-sm-6 col-md-4" :key="i">
          <v-card class="pa-5">

            <a :href="ETHERSCAN_URL_BASE + 'address/' + lottery.address" target="_blank" class="black--text">

              <h2 class="mb-4 text-center">
                <img width="45"
                     v-if="lottery.token === 'PLSD'"
                     src="@/assets/plsdlogov2-modified.png"
                />
                <img width="45"
                     v-if="lottery.token === 'PLSB'"
                     src="@/assets/plsblogo-modified.png"
                />
                <img width="45"
                     v-if="lottery.token === 'ASIC'"
                     src="@/assets/asiclogo.png"
                />
                <img width="45"
                     v-if="lottery.token === 'CARN'"
                     src="@/assets/carn_logo.png"
                />
                <img  width="45" v-if="lottery.token === 'USDC'" src="@/assets/usdc.png" />
                <img  width="45" v-if="lottery.token === 'HEX'" src="@/assets/hex.png" />



                Weekly {{ lottery.token }} Lottery #{{lottery.currentDrawId}}
                <img width="45"
                     v-if="lottery.token === 'PLSD'"
                     src="@/assets/plsdlogov2-modified.png"
                />
                <img width="45"
                     v-if="lottery.token === 'PLSB'"
                     src="@/assets/plsblogo-modified.png"
                />
                <img width="45"
                     v-if="lottery.token === 'ASIC'"
                     src="@/assets/asiclogo.png"
                />
                <img width="45"
                     v-if="lottery.token === 'CARN'"
                     src="@/assets/carn_logo.png"
                />
                <img  width="45" v-if="lottery.token === 'USDC'" src="@/assets/usdc.png" />
                <img  width="45" v-if="lottery.token === 'HEX'" src="@/assets/hex.png" />
              </h2>
            </a>
            <h4>Your Tickets: {{ lottery.yourTickets }} Tickets</h4>
            <h4>Total Tickets: {{ lottery.totalTickets }} Tickets</h4>
            <h4>Jackpot: {{ lottery.totalTickets * Number(generalFormat(lottery.ticketPrice, lottery.decimals)) }} ${{ lottery.token }}</h4>
            <small
            >Each ticket is
              {{ generalFormat(lottery.ticketPrice, lottery.decimals) }}
              {{ lottery.token }} (+ 0.1 CARN per ticket)</small
            >
            <v-divider class="my-5"></v-divider>

            <v-text-field
                v-show="new Date().getTime() < lottery.lotteryDeadline * 1000 && ((lottery.lotteryDeadline*1000) - (new Date().getTime()))/1000 < (lottery.timeLength-50)"
                outlined
                type="text"
                class="mt-3"
                v-model="lottery.amount"
                :label="`Enter the amount of lottery tickets you want to purchase`"
            >
              <template v-slot:append>
                <v-btn
                    text
                    color="primary"
                    small
                    @click="
                    lottery.amount = Math.floor(
                      lottery.balance / lottery.ticketPrice
                    )
                  "
                >
                  MAX
                </v-btn>
              </template>
            </v-text-field>

            <v-btn
                v-if="new Date().getTime() > lottery.lotteryDeadline * 1000 || ((lottery.lotteryDeadline*1000) - (new Date().getTime()))/1000 > (lottery.timeLength-50)"
                @click="onApprove(lottery, true)"
                block
                x-large
                color="primary"
                class="py-5 mb-4"
                :disabled="!getUserAddress || isLoading || lottery.CHAINLINKBalance < 10000000000000000000"
            >
              Select Winner
            </v-btn>

            <v-btn
                v-else
                @click="onApprove(lottery, false)"
                block
                x-large
                color="primary"
                class="py-5 mb-4"
                :disabled="!getUserAddress || isLoading"
            >
              Buy Tickets (Deadline:
              <span :id="lottery.token + 'demo'">0D 0H 0M 0S</span>)
            </v-btn>
            <small>ChainLink Balance: {{ eighteenFormat( lottery.CHAINLINKBalance, 2)}}</small>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <img
                    width="18px"
                    src="@/assets/info.webp"
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <span>Executing the "Select Winner" function costs around 5-10 ChainLink tokens.</span>
            </v-tooltip>

          </v-card>
        </div>
      </template>
    </v-row>
    <small>Note: After selecting a winner for any lottery, it takes a little less than a minute before the next lottery starts. After selecting a winner, wait a minute and refresh.</small>
    <v-row>
      <template v-for="(lottery, i) in lotteries">

        <div class="col-12" v-if="lottery.drawHistories.winner!='0x0000000000000000000000000000000000000000'" :key="i"><!-- -->
          <v-card  class="pa-5">
            <h1>
              <img width="30"
                   v-if="lottery.token === 'PLSD'"
                   src="@/assets/plsdlogov2-modified.png"
              />
              <img width="30"
                   v-if="lottery.token === 'PLSB'"
                   src="@/assets/plsblogo-modified.png"
              />
              <img width="30"
                   v-if="lottery.token === 'ASIC'"
                   src="@/assets/asiclogo.png"
              />
              <img width="30"
                   v-if="lottery.token === 'CARN'"
                   src="@/assets/carn_logo.png"
              />
              <img  width="30" v-if="lottery.token === 'USDC'" src="@/assets/usdc.png" />
              <img  width="30" v-if="lottery.token === 'HEX'" src="@/assets/hex.png" />


              {{lottery.token}} Carnival Lottery #{{lottery.currentDrawId-1}} Winner  - Claim Prize Here</h1>
            <ul>
              <li>
                <strong>{{lottery.drawHistories.winner}} won {{ generalFormat(lottery.drawHistories.totalDeposits, lottery.decimals)}} ${{lottery.token}}!! ---> <v-btn @click="sendReward(lottery)" :disabled="lottery.drawHistories.isRewardClaimed">Claim Prize</v-btn> </strong>
              </li>
            </ul>

          </v-card>
        </div>
      </template>
    </v-row>
  </div>
</template>

<script>
import ABIS from "@/config/ABIS.json";
export default {
  name: "LotteriesWeek",
  data() {
    return {
      CHAINLINKBalance: 0,
      lotteries: [
        {
          instance: null,
          token: "PLSD",
          yourTickets: 0,
          totalTickets: 0,
          ticketPrice: 0,
          amount: 0,
          balance: 0,
          lotteryDeadline: null,
          clearInterval: null,
          decimals: "twelve",
          currentDrawId: 0,
          timeLength: 0,
          drawHistories: null
        },
        {
          instance: null,
          token: "PLSB",
          yourTickets: 0,
          totalTickets: 0,
          ticketPrice: 0,
          amount: 0,
          balance: 0,
          lotteryDeadline: null,
          clearInterval: null,
          decimals: "twelve",
          currentDrawId:0,
          timeLength: 0,
          drawHistories: null

        },
        {
          instance: null,
          token: "ASIC",
          yourTickets: 0,
          totalTickets: 0,
          ticketPrice: 0,
          amount: 0,
          balance: 0,
          lotteryDeadline: null,
          clearInterval: null,
          decimals: "twelve",
          currentDrawId:0,
          timeLength: 0,
          drawHistories: null

        },
        {
          instance: null,
          token: "CARN",
          yourTickets: 0,
          totalTickets: 0,
          ticketPrice: 0,
          amount: 0,
          balance: 0,
          lotteryDeadline: null,
          clearInterval: null,
          decimals: "twelve",
          currentDrawId:0,
          timeLength: 0,
          drawHistories: null

        },
        {
          instance: null,
          token: "USDC",
          yourTickets: 0,
          totalTickets: 0,
          ticketPrice: 0,
          amount: 0,
          balance: 0,
          lotteryDeadline: null,
          clearInterval: null,
          decimals: "six",
          currentDrawId:0,
          timeLength: 0,
          drawHistories: null

        },
        {
          instance: null,
          token: "HEX",
          yourTickets: 0,
          totalTickets: 0,
          ticketPrice: 0,
          amount: 0,
          balance: 0,
          lotteryDeadline: null,
          clearInterval: null,
          decimals: "eight",
          currentDrawId:0,
          timeLength: 0,
          drawHistories: null

        },
      ],
    };
  },
  mounted() {
    if (this.getUserAddress) this.readValues();
  },
  methods: {
    readValues() {
      this.lotteries.forEach((lottery) => {
        lottery.address = this[lottery.token + "_WEEK_LOTTERY_ADDRESS"];
        lottery.instance = new this.getWeb3.eth.Contract(
            ABIS.LOTTERY_ABI,
            lottery.address
        );
      });
      this.lotteries.forEach((lottery) => {
        let address = this.getUserAddress;
        Promise.all([
          this["get" + lottery.token + "Instance"].methods
              .allowance(address, lottery.address)
              .call(),
          this.getCARNInstance.methods
              .allowance(address, lottery.address)
              .call(),
          this["get" + lottery.token + "Instance"].methods
              .balanceOf(address)
              .call(),
          this.getCHAINLINKInstance.methods.balanceOf(this[lottery.token + "_WEEK_LOTTERY_ADDRESS"]).call(),
          //lottery.instance.methods.getTicketsSold().call(),
          lottery.instance.methods.getTicketsSold().call(),
          lottery.instance.methods.lotteryDeadline().call(),
          lottery.instance.methods.getPlayerEntries(address).call(),
          lottery.instance.methods.ticketPrice().call(),
          lottery.instance.methods.getCurrentDrawId().call(),
          lottery.instance.methods.timeLength().call(),
        ]).then(
            async ([
                     isApproved,
                     isCARNApproved,
                     balance,
                     CHAINLINKBalance,
                     totalTickets,
                     lotteryDeadline,
                     yourTickets,
                     ticketPrice,
                     currentDrawId,
                     timeLength,
                   ]) => {
              console.log("isApproved", !!Number(isApproved), isApproved);
              console.log(
                  "isCARNApproved",
                  !!Number(isCARNApproved),
                  isCARNApproved
              );
              console.log("balance", balance);
              console.log("totalTickets", totalTickets);
              console.log("lotteryDeadline", lotteryDeadline);
              console.log("yourTickets", yourTickets);
              console.log("ticketPrice", ticketPrice);
              console.log("currentDrawId", currentDrawId);
              console.log("timeLength", timeLength);
              console.log("now", new Date().getTime());
              console.log("lotteryDeadline", lotteryDeadline)
              console.log("deadline - now -->", ((lotteryDeadline*1000) - (new Date().getTime()))/1000)
              console.log("token name", lottery.token)


              console.log("balance of chainlinke", await this.getCHAINLINKInstance.methods.balanceOf(this[lottery.token + "_WEEK_LOTTERY_ADDRESS"]))
              console.log("lottery address", this[lottery.token + "_WEEK_LOTTERY_ADDRESS"])
              console.log("this.CHAINLINKInstance", this.getCHAINLINKInstance)
              console.log("CHAINLINKBalance", CHAINLINKBalance)


              console.log("----------------");
              lottery.isApproved = Number(isApproved) > 0 ? true : false;
              lottery.isCARNApproved = Number(isCARNApproved) > 0 ? true : false;

              lottery.balance = balance;
              lottery.CHAINLINKBalance = CHAINLINKBalance;
              lottery.totalTickets = totalTickets;
              lottery.yourTickets = yourTickets;
              lottery.lotteryDeadline = lotteryDeadline;
              lottery.ticketPrice = ticketPrice;
              lottery.currentDrawId = currentDrawId;
              lottery.timeLength = timeLength;
              lottery.drawHistories = await lottery.instance.methods
                  .drawHistory(currentDrawId - 1)
                  .call();
              //console.log("drawHistories", lottery.drawHistories)
              console.log("drawHistories.isRewardClaimed", lottery.drawHistories.isRewardClaimed)
              console.log("drawHistories.totalDeposits", lottery.drawHistories.totalDeposits)
              console.log("drawHistories.winner", lottery.drawHistories.winner)
              console.log("name", lottery.token)

              clearInterval(lottery.clearInterval);
              this.counter(lottery);
            }
        );
      });
    },
    counter(lottery) {
      let countDownDate = lottery.lotteryDeadline * 1000;

      lottery.clearInterval = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now + 20000;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        let element = document.getElementById(lottery.token + "demo");
        if (element) {
          if (distance <= 0) {
            clearInterval(lottery.clearInterval);
            element.innerHTML = `0D 0H 0M 0S`;
          } else {
            element.innerHTML = `${days}D ${hours}H ${minutes}M ${seconds}S`;
          }
        }
      }, 1000);
    },
    onApprove(lottery, isWinner) {
      if (lottery.isApproved) {
        if (lottery.isCARNApproved) this.buyTickets(lottery, isWinner);
        else this.onCARNApprove(lottery);
        return;
      }
      this.SET_LOADING(true);
      this["get" + lottery.token + "Instance"].methods
          .approve(lottery.address, "1000000000000000000000000000")
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show(`Approving your ${lottery.token}!`);
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show(`Your ${lottery.token} is approved!`);
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show(`Sorry, approving your ${lottery.token} failed.`);
          });
    },

    onCARNApprove(lottery) {
      this.SET_LOADING(true);
      this.getCARNInstance.methods
          .approve(lottery.address, "1000000000000000000000000000")
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("Approving your CARN!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("Your CARN is approved!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("Sorry, approving your CARN failed.");
          });
    },

    buyTickets(lottery, isWinner) {
      if (
          Number(lottery.amount) >
          this.generalFormat(lottery.balance, lottery.decimals)
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }
      this.SET_LOADING(true);
      let amount = isWinner ? 0 : lottery.amount;
      lottery.instance.methods
          .buyTickets(amount)
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("Lottery Ticket Purchase In Progress!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.clearForm();
            this.SET_LOADING(false);
            this.$toasted.show("Lottery Tickets Purchased!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("Purchasing Lottery Tickets Failed");
          });
    },
    sendReward(lottery) {

      this.SET_LOADING(true);
      lottery.instance.methods
          .sendRewards(lottery.currentDrawId-1)
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("Jackpot is being sent to the winner!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.clearForm();
            this.SET_LOADING(false);
            this.$toasted.show("Jackpot has been sent to the winner!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("Jackpot failed to be sent to the winner");
          });
    },
  },
  watch: {
    getUserAddress() {
        if (this.getUserAddress)
          this.readValues();
    },
  },
};
</script>
