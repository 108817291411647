<template>
  <div>
    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('PLSB')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="blue">
              <img width="50px" src="@/assets/plsblogo-modified.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(PLSBBalance) }} $PLSB <br />
                Bought & Burned
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('PLSB')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="blue">
              <img width="50px" src="@/assets/plsblogo-modified.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(plsbSentToWaatca) }} $PLSB <br />
                Bought + Sent To WAATCA
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>

    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('ASIC')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="purple darken-2">
              <img width="50px" src="@/assets/asiclogo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(ASICBalance) }} $ASIC <br />
                Bought & Burned
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('ASIC')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="purple darken-2">
              <img width="50px" src="@/assets/asiclogo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(asicSentToWaatca) }} $ASIC <br />
                Bought + Sent To WAATCA
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>

    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('PLSD')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img width="50px" src="@/assets/plsdlogov2-modified.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(PLSDBalance) }} $PLSD <br />
                Bought & Burned
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('PLSD')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img width="50px" src="@/assets/plsdlogov2-modified.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(plsdSentToWaatca) }} $PLSD <br />
                Bought + Sent To WAATCA
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>

    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('HEX')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="purple darken-2">
              <img width="50px" src="@/assets/hex.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ eightFormat(HEXBalance) }} $HEX <br />
                Bought & Burned
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('HEX')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="purple darken-2">
              <img width="50px" src="@/assets/hex.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ eightFormat(hexSentToWaatca) }} $HEX <br />
                Bought + Sent To WAATCA
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>

    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img width="50px" src="@/assets/carn_logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(CARNBalance) }} $CARN <br />
                Burned
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" target="_blank" :href="deadPage('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="blue">
              <img width="50px" src="@/assets/logo_gold_lock.jpeg" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(carnSentToPulseBitcoinLockNftRewards) }} $CARN
                <br />
                Sent to PulseBitcoinLock NFT "CARN Rewards" Pool
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>

    <v-row class="justify-center align-center" style="height: 100%">
      <div class="col-12">
        <v-row class="justify-center">
          <div class="col-12 col-sm-8">
            <v-card class="pa-5">
              <h1 class="grey--text text--darken-2">BUY & BURN</h1>
              <h4 class="font-weight-regular">
                - {{ twelveFormat(CARNBalanceOfContract) }} CARN available for
                Buy & Burn <br />
                - For each button you press, you get 5% of the CARN for that
                transaction :) Enjoy!
              </h4>
              <v-divider class="my-5"></v-divider>

              <v-btn
                @click="onProcessCarn(6, processable_six)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="!getUserAddress || isLoading || processable_six == 0"
              >
                BUY PLSB WITH {{ twelveFormat(processable_six, 2) }} $CARN AND
                BURN IT
              </v-btn>
              <v-btn
                @click="onProcessCarn(7, processable_seven)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="
                  !getUserAddress || isLoading || processable_seven == 0
                "
              >
                BUY ASIC WITH {{ twelveFormat(processable_seven, 2) }} $CARN AND
                BURN IT
              </v-btn>
              <v-btn
                @click="onProcessCarn(5, processable_five)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="
                  !getUserAddress || isLoading || processable_five == 0
                "
              >
                BUY PLSD WITH {{ twelveFormat(processable_five, 2) }} $CARN AND
                BURN IT
              </v-btn>
              <v-btn
                @click="onProcessCarn(9, processable_nine)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="
                  !getUserAddress || isLoading || processable_nine == 0
                "
              >
                BUY HEX WITH {{ twelveFormat(processable_nine, 2) }} $CARN AND
                BURN IT
              </v-btn>

              <v-btn
                @click="onProcessCarn(3, processable_three)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="
                  !getUserAddress || isLoading || processable_three == 0
                "
              >
                BUY PLSB WITH {{ twelveFormat(processable_three, 2) }} $CARN AND
                SEND TO WAATCA
              </v-btn>
              <v-btn
                @click="onProcessCarn(4, processable_four)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="
                  !getUserAddress || isLoading || processable_four == 0
                "
              >
                BUY ASIC WITH {{ twelveFormat(processable_four, 2) }} $CARN AND
                SEND TO WAATCA
              </v-btn>
              <v-btn
                @click="onProcessCarn(2, processable_two)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="!getUserAddress || isLoading || processable_two == 0"
              >
                BUY PLSD WITH {{ twelveFormat(processable_two, 2) }} $CARN AND
                SEND TO WAATCA
              </v-btn>
              <v-btn
                @click="onProcessCarn(8, processable_eight)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="
                  !getUserAddress || isLoading || processable_eight == 0
                "
              >
                BUY HEX WITH {{ twelveFormat(processable_eight, 2) }} $CARN AND
                SEND TO WAATCA
              </v-btn>

              <v-btn
                @click="onProcessCarn(1, processable_one)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="!getUserAddress || isLoading || processable_one == 0"
              >
                SEND {{ twelveFormat(processable_one, 2) }} $CARN TO
                PULSEBITCOINLOCK NFT <br />
                "FREE $CARN POOL"
              </v-btn>
              <v-btn
                @click="onProcessCarn(0, processable_zero)"
                block
                x-large
                color="yellow"
                class="py-5 mb-4"
                :disabled="
                  !getUserAddress || isLoading || processable_zero == 0
                "
              >
                BURN {{ twelveFormat(processable_zero, 2) }} $CARN
              </v-btn>
            </v-card>
          </div>
        </v-row>
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "BuyAndBurn",
  data() {
    return {
      CARNBalanceOfContract: 0,
      processable_zero: 0,
      processable_one: 0,
      processable_two: 0,
      processable_three: 0,
      processable_four: 0,
      processable_five: 0,
      processable_six: 0,
      processable_seven: 0,
      processable_eight: 0,
      processable_nine: 0,

      PLSBBalance: 0,
      ASICBalance: 0,
      PLSDBalance: 0,
      HEXBalance: 0,
      CARNBalance: 0,
      plsbSentToWaatca: 0,
      asicSentToWaatca: 0,
      plsdSentToWaatca: 0,
      hexSentToWaatca: 0,
      carnSentToPulseBitcoinLockNftRewards: 0,
    };
  },
  async mounted() {
    if (this.getUserAddress) this.readValues();
  },
  methods: {
    deadPage(token) {
      let url = "";
      switch (token) {
        case "PLSB":
          url =
            this.ETHERSCAN_URL_BASE +
            "token/" +
            this.PLSB_ADDRESS +
            "?a=0x0000000000000000000000000000000000deadca";
          break;
        case "ASIC":
          url =
            this.ETHERSCAN_URL_BASE +
            "token/" +
            this.ASIC_ADDRESS +
            "?a=0x0000000000000000000000000000000000deadca";
          break;
        case "PLSD":
          url =
            this.ETHERSCAN_URL_BASE +
            "token/" +
            this.PLSD_ADDRESS +
            "?a=0x0000000000000000000000000000000000deadca";
          break;
        case "HEX":
          url =
            this.ETHERSCAN_URL_BASE +
            "token/" +
            this.HEX_ADDRESS +
            "?a=0x0000000000000000000000000000000000deadca";
          break;
        case "CARN":
          url =
            this.ETHERSCAN_URL_BASE +
            "token/" +
            this.CARN_ADDRESS +
            "?a=0x0000000000000000000000000000000000deadca";
          break;
      }
      return url;
    },
    readValues() {
      console.log(this.getBurnInstance.methods);
      Promise.all([
        this.getCARNInstance.methods.balanceOf(this.BURN_ADDRESS).call(),
        this.getBurnInstance.methods.processable(0).call(),
        this.getBurnInstance.methods.processable(1).call(),
        this.getBurnInstance.methods.processable(2).call(),
        this.getBurnInstance.methods.processable(3).call(),
        this.getBurnInstance.methods.processable(4).call(),
        this.getBurnInstance.methods.processable(5).call(),
        this.getBurnInstance.methods.processable(6).call(),
        this.getBurnInstance.methods.processable(7).call(),
        this.getBurnInstance.methods.processable(8).call(),
        this.getBurnInstance.methods.processable(9).call(),
        this.getBurnInstance.methods.plsbSentToWaatca().call(),
        this.getBurnInstance.methods.asicSentToWaatca().call(),
        this.getBurnInstance.methods.plsdSentToWaatca().call(),
        this.getBurnInstance.methods.hexSentToWaatca().call(),
        this.getBurnInstance.methods
          .carnSentToPulseBitcoinLockNftRewards()
          .call(),
        this.getPLSBInstance.methods
          .balanceOf("0x0000000000000000000000000000000000deadca")
          .call(),
        this.getASICInstance.methods
          .balanceOf("0x0000000000000000000000000000000000deadca")
          .call(),
        this.getPLSDInstance.methods
          .balanceOf("0x0000000000000000000000000000000000deadca")
          .call(),
        this.getHEXInstance.methods
          .balanceOf("0x0000000000000000000000000000000000deadca")
          .call(),
        this.getCARNInstance.methods
          .balanceOf("0x0000000000000000000000000000000000deadca")
          .call(),
      ]).then(
        ([
          CARNBalanceOfContract,
          processable_zero,
          processable_one,
          processable_two,
          processable_three,
          processable_four,
          processable_five,
          processable_six,
          processable_seven,
          processable_eight,
          processable_nine,
          plsbSentToWaatca,
          asicSentToWaatca,
          plsdSentToWaatca,
          hexSentToWaatca,
          carnSentToPulseBitcoinLockNftRewards,
          PLSBBalance,
          ASICBalance,
          PLSDBalance,
          HEXBalance,
          CARNBalance,
        ]) => {
          console.log("CARNBalanceOfContract:", CARNBalanceOfContract);
          console.log("processable_zero:", processable_zero);
          console.log("processable_one:", processable_one);
          console.log("processable_two:", processable_two);
          console.log("processable_three:", processable_three);
          console.log("processable_four:", processable_four);
          console.log("processable_five:", processable_five);
          console.log("processable_six:", processable_six);
          console.log("processable_seven:", processable_seven);
          console.log("processable_eight:", processable_eight);
          console.log("processable_nine:", processable_nine);

          console.log("plsbSentToWaatca:", plsbSentToWaatca);
          console.log("asicSentToWaatca:", asicSentToWaatca);
          console.log("plsdSentToWaatca:", plsdSentToWaatca);
          console.log("hexSentToWaatca:", hexSentToWaatca);
          console.log(
            "carnSentToPulseBitcoinLockNftRewards:",
            carnSentToPulseBitcoinLockNftRewards
          );
          console.log("PLSBBalance:", PLSBBalance);
          console.log("ASICBalance:", ASICBalance);
          console.log("PLSDBalance:", PLSDBalance);
          console.log("HEXBalance:", HEXBalance);
          console.log("CARNBalance:", CARNBalance);

          this.CARNBalanceOfContract = CARNBalanceOfContract;
          this.processable_zero = processable_zero;
          this.processable_one = processable_one;
          this.processable_two = processable_two;
          this.processable_three = processable_three;
          this.processable_four = processable_four;
          this.processable_five = processable_five;
          this.processable_six = processable_six;
          this.processable_seven = processable_seven;
          this.processable_eight = processable_eight;
          this.processable_nine = processable_nine;
          this.plsbSentToWaatca = plsbSentToWaatca;
          this.asicSentToWaatca = asicSentToWaatca;
          this.plsdSentToWaatca = plsdSentToWaatca;
          this.hexSentToWaatca = hexSentToWaatca;
          this.carnSentToPulseBitcoinLockNftRewards =
            carnSentToPulseBitcoinLockNftRewards;

          this.PLSBBalance = PLSBBalance;
          this.ASICBalance = ASICBalance;
          this.PLSDBalance = PLSDBalance;
          this.HEXBalance = HEXBalance;
          this.CARNBalance = CARNBalance;
        }
      );
    },
    onProcessCarn(op, amount) {
      // console.log(amount)
      // console.log(typeof(Number(amount)))
      if (Number(amount) > 369000000000000) {
        amount = "369000000000000";
      }
      console.log("after", amount);
      console.log(typeof Number(amount));
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getBurnInstance.methods
        .processCarn(op, amount)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Buy and Burn transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Buy and Burn transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Buy and Burn transaction has Failed");
        });
    },
    onBurn() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getBurnInstance.methods
        .buyCARN()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Burn transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Burn transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Burn transaction has Failed");
        });
    },
  },
  watch: {
    getUserAddress() {
      if (this.getUserAddress) this.readValues();
    },
  },
};
</script>
<style>
.v-main.BuyAndBurn .v-main__wrap {
  background-image: url("../assets/burn.webp");
  background-size: 100%;
  background-position: center;
}
</style>
