<template>
  <div>
    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-2">
        <v-card class="mx-auto">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/calendar.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ currentDay }} <br />
                Current Day
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <v-card
          class="mx-auto"
          :href="ETHERSCAN_URL_BASE + 'address/' + PULSE_BITCOIN_LOCK_ADDRESS"
          target="_blank"
        >
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/carn_logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(CARNBalance) }} <br />
                CARN Balance
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/hex.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ eightFormat(HEXBalance,9) }} <br />
                Your HEX Balance
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/hex.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ eightFormat(HEXInContract,3) }} <br />
                HEX Rewards Available
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/hex.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ eightFormat(totalFreeHexWithdrawn,3) }} <br />
                Total HEX Withdrawn
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>

    <v-row v-show="getUserAddress && nfts.length" class="justify-center">
      <div class="col-12 col-sm-6 col-md-5">
        <v-card class="mx-auto text-center">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="endOfDay">0 Hours 0 Minutes 0 Seconds</h3>
              </v-list-item-title>
              <h4 class="font-weight-regular">
                until the next days HEX Rewards will be available for withdrawal.
              </h4>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>
    <v-row>
      <div class="col-12" v-bind:class="(notEnoughHexRewardsAvailable)?'disabledSection':''">
        <v-card class="pa-5">
          <h1 class="grey--text text--darken-2">HEX Rewards


            <v-tooltip bottom>
              <br>


              <template v-slot:activator="{ on, attrs }">
                <img
                    width="18px"
                    src="@/assets/info.webp"
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <span>
          <ul>
            * To get HEX Rewards, you must register your PulseBitcoinLock NFT for rewards before the Unlock Time
          </ul>
          <ul>
            * You only get rewards starting the day you register.
          </ul>
          <ul>
            * Upon Registering you'll get your days worth of rewards and when
            the countdown timer hits zero, you can claim again.
          </ul>
          <ul>
            * You dont have to claim every day. You can wait as many days as you want and then
            claim your $HEX (similar to $HDRN except without the borrowing!)
          </ul>
          <ul>
            * As long as your NFT is registered before the Unlock Time, you can still collect your HEX Rewards even after the Unlock
            Time is over.
          </ul>
          <ul>
            * The amount of CARN Rewards you can collect every day is equal to
            (amount_locked_up) * (days_locked_up) * (0.0000015)
          </ul>
          <ul>
            * For that formula, the amount_locked_up and the days_locked_up are both capped at 1000. Meaning,
            an NFT that has 5000 PLSB locked up for 5000 days will still get 1.5
            CARN Rewards per day.
          </ul>
              </span>
            </v-tooltip>
          </h1>
          <small>Total # of NFTs Registered for Hex Rewards: {{twelveFormat( this.currentRegistrationFee) - 369}}</small><br>
          <small>Current CARN Registration Fee: {{twelveFormat( this.currentRegistrationFee)}} CARN</small><br>
          <small>Total CARN Burnt via Registration Fees: {{Array.from({ length: twelveFormat( this.currentRegistrationFee) - 368 }, (_, i) => i + 369).reduce((acc, val) => acc + val, 0)}} CARN</small>

          <h3 v-show="(notEnoughHexRewardsAvailable)">MAY I HAVE YOUR ATTENTION PLEASE: THIS SECTION IS DISABLED WHENEVER THERE IS NOT AT LEAST 200 HEX IN THE REWARDS
          POOL AVAILABLE IN THE CONTRACT. Either deposit at least 200 HEX in the contract at 0xeb9014610d4daC128f9DA00C397Ce9119Ee777F5
            or wait until the Hex Community Staker finishes a 60 day cycle and more HEX gets deposited in the rewards pool that way.
          </h3>

          <v-divider class="my-6"></v-divider>
          <template v-if="!getUserAddress">
            <div class="d-flex justify-center">
              <p class="mt-5 mb-10 text-center">
                Connect your wallet to see your NFT's
              </p>
            </div>
          </template>
          <template v-else-if="isNFTLoading">
            <div class="d-flex justify-center my-10">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </template>
          <template v-else-if="!nfts.length">
            <div class="d-flex justify-center">
              <p class="mt-5 text-center">
                HEX Rewards is for PulseBitcoinLock NFT Holders only.
                <br> Head on over to <a href="https://PulseBitcoinLock.app/" target="_blank"><strong>PulseBitcoinLock.app</strong></a>
                and Lock Up some PulseBitcoin, then come back here to get HEX Rewards.
              </p>
            </div>
          </template>
          <div class="row">
            <template v-for="(nft, i) in nfts">
              <div class="col-12" :key="i">
                <div class="d-block d-md-flex text-center text-md-left">
                  <div>
                    <a href="http://pulsebitcoinlock.app" target="_blank"><img class="nft_img" :src="nft.image" /></a>
                  </div>
                  <div>
                    <div class="mb-1"><b>Id: </b>{{ nft.id }}</div>
                    <div class="mb-1">
                      <b>Total Lock Time: </b>{{ HHMMSS(nft.lockTime) }}
                    </div>
                    <div class="mb-1">
                      <b>Unlock Time (must register before this time): </b
                      >{{ humanDate(nft.unlockTime) }}
                    </div>
                    <div class="mb-1">
                      <b>Locked Amount: </b
                      >{{ twelveFormat(nft.lockedAmount) }} PLSB
                    </div>
                    <div class="mb-1" v-if="nft.transferable">
                      <b>This NFT is Transferable</b>
                    </div>
                    <div class="mb-1" v-if="!nft.transferable">
                      <b>This NFT is Non-Transferable</b>
                    </div>

                    <div
                      v-show="
                        isRegisterAllowed(nft.unlockTime) && nft.isRegistered
                      "
                    >
                      <div class="mb-1">
                        <b>Daily Reward: </b
                        >{{ eightFormat(nft.reward, 2) }} $HEX
                      </div>
                      <div class="mb-1">
                        <b>Days Since Last Withdrawal: </b
                        >{{ currentDay - nft.withdrawalDay }} Days
                      </div>
                      <div class="mb-1">
                        <b>Rewards End On Day: </b>{{ nft.endRewardDay }}
                      </div>

                    </div>
                    <template v-if="nft.isRegistered">
                      <v-btn
                        v-if="cannotWithdrawCarn(nft.withdrawalDay, nft.endRewardDay)"
                        small
                        color="mt-1 yellow"
                        class="px-7"
                        :disabled="true"
                        >Come back tomorrow to withdraw more $HEX
                      </v-btn>
                      <v-btn
                        v-else
                        small
                        color="mt-1 yellow"
                        class="px-7"
                        @click="onWithdraw(nft.id)"
                        >{{
                          `Withdraw ${
                            eightFormat((currentDay - nft.withdrawalDay) * nft.reward, 2)
                          } Free $HEX!!`
                        }}
                      </v-btn>
                      <small> </small>
                    </template>
                    <v-btn
                      v-else
                      small
                      color="mt-1 yellow"
                      class="px-7"
                      :disabled="!isRegisterAllowed(nft.unlockTime)"
                      @click="onRegister(nft.id)"
                    >
                      {{
                        !isRegisterAllowed(nft.unlockTime)
                          ? "Cannot Register - NFT expired"
                          : "Register NFT For Free $HEX"
                      }}
                    </v-btn> <br>
                    <small v-if="isRegisterAllowed(nft.unlockTime) && !nft.isRegistered">
                      Current Registration Fee is {{twelveFormat( currentRegistrationFee)}} CARN
                    </small>
                  </div>
                </div>
                <v-divider class="my-3"></v-divider>
              </div>
            </template>
          </div>

          <div v-show="!shouldDisableBulkRegistration || nfts.length>0">
            <br>
            <v-btn
                small
                color="mt-1 yellow"
                class="px-7"
                style="margin-right: 50px"
                :disabled="shouldDisableBulkRegistration"
                @click="onBulkRegister(NftsThatCanBeRegistered.map(nft => nft.id))"
            >
              {{
                "Bulk Register"
              }} <br>
            </v-btn>


            <v-btn
                small
                color="mt-1 yellow"
                class="px-7"
                :disabled="shouldDisableBulkWithdrawal"
                @click="onBulkWithdraw(NftsThatCanBeWithdrawed.map(nft => nft.id))"
            >
              {{
                "Bulk Withdraw"
              }}
            </v-btn><br>



            <small v-show="getBulkRegisterCarnFee( NftsThatCanBeRegistered.map(nft => nft.id))>0">
              Bulk Register CARN Fee: {{ twelveFormat(getBulkRegisterCarnFee( NftsThatCanBeRegistered.map(nft => nft.id))) }}
            </small>
          </div>


        </v-card>
      </div>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "FreeHex",
  data() {
    return {
      nfts: [],
      totalNFTS: 0,
      currentDay: 0,
      HEXBalance: 0,
      HEXInContract: 0,
      isNFTLoading: true,
      clearIntervalX: null,
      totalFreeHexWithdrawn: 0,
      isCARNApproved: false,
      isBULKCARNApproved: false,
      CARNBalance: 0,
      currentRegistrationFee: 0,
      CARNallowance: 0,


    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalX);
  },
  async mounted() {
    if (this.getUserAddress) 
      this.readValues();
    
  },
  methods: {
    compareValues(key) {
      return (a, b) => {
        let comparison = 0;
        if (a[key] > b[key]) {
          comparison = 1;
        } else if (a[key] < b[key]) {
          comparison = -1;
        }
        return comparison;
      };
    },
    readValues() {
      Promise.all([
        this.getNftHexRewardsInstance.methods.currentDay().call(),
        this.getHEXInstance.methods.balanceOf(this.getUserAddress).call(),
        this.getHEXInstance.methods.balanceOf(this.NFT_HEX_REWARDS_ADDRESS).call(),
        this.getNftHexRewardsInstance.methods.totalFreeHexWithdrawn().call(),
        this.getCARNInstance.methods.allowance(this.getUserAddress, this.NFT_HEX_REWARDS_ADDRESS).call(),
        this.getCARNInstance.methods.balanceOf(this.getUserAddress).call(),
        this.getNftHexRewardsInstance.methods.CARN_FEE().call(),

      ]).then(([currentDay, HEXBalance, HEXInContract, totalFreeHexWithdrawn, isCARNApproved, CARNBalance, currentRegistrationFee]) => {
        this.currentDay = currentDay;
        // console.log("CARN allowance: ", isCARNApproved);

        this.HEXBalance = HEXBalance;
        this.HEXInContract = HEXInContract;
        this.totalFreeHexWithdrawn = totalFreeHexWithdrawn;
        this.CARNBalance = CARNBalance;
        this.currentRegistrationFee = Number(currentRegistrationFee);
        this.CARNallowance = Number(isCARNApproved);
        // console.log("CARN allowance: ", isCARNApproved);

        this.isCARNApproved = isCARNApproved >= this.currentRegistrationFee;


        // console.log("currentRegistrationFee: ", this.currentRegistrationFee)

        // console.log("end read values")


        clearInterval(this.clearIntervalX);
        this.counter();
        this.getUserNFTs();
      });
      // console.log("end read values")
    },
    counter() {
      const endOfDay = new Date().setUTCHours(23, 59, 59, 999);
      this.clearIntervalX = setInterval(function () {
        let now = new Date().getTime();
        // console.log("^^^^^^^^^^^^^^^^^^now:", now)
        // console.log("^^^^^^^^^^^^^endOfDay:", endOfDay)
        const distance = endOfDay - now;
        const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalX);
          document.getElementById(
              "endOfDay"
          ).innerHTML = `0 Hours 0 Minutes 0 Seconds`;
        } else {
          document.getElementById(
              "endOfDay"
          ).innerHTML = `${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
          // let btns = document.getElementsByClassName("cannotWithdrawCarn");
          // if (btns && btns.length) {
          //   Array.prototype.forEach.call(btns, (el) => {
          //     el.innerHTML = `
          //     Come back in ${hours} Hours ${minutes} Minutes ${seconds} Seconds to withdraw more $HEX`;
          //   });
          // }
        }
      }, 1000);
    },
    getUserNFTs() {
      // console.log("get user nfts")
      this.nfts = [];
      this.isNFTLoading = true;
      let URL = `https://api.thegraph.com/subgraphs/name/korkey128k/pulsebitcoinlocknft`; // use this for mainnet
      //let URL = `https://api.studio.thegraph.com/query/29707/pulsebitcoinlock/1.2.0`;
      //let URL = `https://api.studio.thegraph.com/query/29707/pulsebitcoinlock/1.2.1`;
      if(this.walletChainID == 369) {
        URL = `https://pulse.graph.korkey.tech/subgraphs/name/pulsebitcoinlock`; // use this for mainnet
      }

      const query = `
                    {
                      owner(id:"${this.getUserAddress.toLowerCase()}") {
                        id
                        nfts {
                          id
                          minter
                          lockedAmount
                          tipAmount
                          lockTime
                          unlockTime
                          uri
                          tokenId
                          timestamp
                          burner
                          burned
                          burnTime
                          transferable
                        }
                      }
                    }
                    `;
      const headers = {
        "Content-Type": "application/json",
      };
      // console.log('hello');

      axios
          .post(URL, {query: query}, {headers: headers})
          .then((response) => {
            let data = response.data.data;
            let nfts = data.owner ? data.owner.nfts : [];
            // console.log(nfts);
            if (nfts.length) {
              nfts.forEach(async (nft, i) => {
                console.log("nft.uri: ", nft.uri)

                let uri = this.geturi(nft.uri);
                console.log("uri: ", uri)

                axios
                    .get(uri)
                    .then((response) => {
                      nft.name = response.data.name;
                      nft.image = this.geturi(response.data.image);
                      console.log("nft.image: ", nft.image)
                      Promise.all([
                        this.getNftHexRewardsInstance.methods
                            .tokenIdsToRegistered(nft.id)
                            .call(),
                        this.getNftHexRewardsInstance.methods
                            .tokenIdsToDailyRewardAmount(nft.id)
                            .call(),
                        this.getNftHexRewardsInstance.methods
                            .tokenIdsToLastWithdrawalDay(nft.id)
                            .call(),
                        this.getNftHexRewardsInstance.methods
                            .tokenIdsToEndRewardsDay(nft.id)
                            .call(),
                      ]).then(
                          ([isRegistered, reward, withdrawalDay, endRewardDay]) => {
                            // console.log("isRegistered: ", isRegistered);
                            // console.log("REWARD: ", reward);
                            // console.log("WithdrawalDay: ", withdrawalDay);
                            nft.reward = reward;
                            nft.isRegistered = isRegistered;
                            nft.withdrawalDay = withdrawalDay;
                            nft.endRewardDay = endRewardDay;
                            this.nfts.push(nft);
                            if (i + 1 === nfts.length) {
                              this.nfts.sort(this.compareValues("id"));
                            }
                            this.isNFTLoading = false;
                          }
                      );
                    })
                    .catch((error) => {
                      console.log(error);
                      this.isNFTLoading = false;
                    });
              });
            } else {
              this.isNFTLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.isNFTLoading = false;
          });
    },

    onRegister(tokenId) {

      if (!this.isCARNApproved){
        this.onCARNApprove();
        return;
      }

      this.SET_LOADING(true);
      this.getNftHexRewardsInstance.methods
        .registerNftForRewards(tokenId)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Register transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Register transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Register transaction has Failed");
        });
    },
    getBulkRegisterCarnFee(tokenIds) {
      let start = this.currentRegistrationFee;
      let totalCarnForBulkRegistration = 0;
      // console.log(totalCarnForBulkRegistration);
      for (let i = 0; i < tokenIds.length; i++) {
        totalCarnForBulkRegistration += start + i* 1e12;
      }
      return totalCarnForBulkRegistration;
    },
    onBulkRegister(tokenIds) {

      let start = this.currentRegistrationFee;
      let totalCarnForBulkRegistration = 0;
      // console.log(totalCarnForBulkRegistration);
      for (let i = 0; i < tokenIds.length; i++) {
        totalCarnForBulkRegistration += start + i* 1e12;
      }
      console.log("totalCarnForBulkRegistration: ", totalCarnForBulkRegistration);
      console.log("CARNallowance: ", this.CARNallowance);

      if (this.CARNallowance < totalCarnForBulkRegistration){
        this.onCARNApprove();
        return;
      }
      let baseTxnFee = 0.001;
      let txnFee = baseTxnFee * tokenIds.length;

      if(txnFee < baseTxnFee * 10) {
        txnFee = baseTxnFee * 10;
      }
      console.log("txnFee: ", txnFee);


      this.SET_LOADING(true);
      this.getNftHexRewardsInstance.methods
          .bulkRegister(tokenIds)
          .send({
            from: this.getUserAddress,
            value: this.getWeb3.utils.toWei(txnFee.toString(), "ether")
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("Bulk Register transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("Bulk Register transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("Bulk Register transaction has Failed");
          });
    },
    onBulkWithdraw(tokenIds) {


      let baseTxnFee = 0.001;
      let txnFee = baseTxnFee * tokenIds.length;

      if(txnFee < baseTxnFee * 10) {
        txnFee = baseTxnFee * 10;
      }
      console.log("txnFee: ", txnFee);


      this.SET_LOADING(true);
      this.getNftHexRewardsInstance.methods
          .bulkWithdraw(tokenIds)
          .send({
            from: this.getUserAddress,
            value: this.getWeb3.utils.toWei(txnFee.toString(), "ether")
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("Bulk Withdrawal transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("Bulk Withdrawal transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("Bulk Withdrawal transaction has Failed");
          });
    },
    onCARNApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getCARNInstance.methods
          .approve(this.NFT_HEX_REWARDS_ADDRESS, "1000000000000000000000000000")
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.$toasted.show("CARN Approve transaction is Processing!");
          })
          .on("receipt", (receipt) => {
            console.log(receipt);
            this.readValues();
            this.SET_LOADING(false);
            this.$toasted.show("CARN Approve transaction has Completed!");
          })
          .on("error", (error, receipt) => {
            console.log(error, receipt);
            this.SET_LOADING(false);
            this.$toasted.show("CARN Approve transaction has Failed");
          });
    },

    onWithdraw(tokenId) {
      this.SET_LOADING(true);
      this.getNftHexRewardsInstance.methods
        .withdrawRewards(tokenId)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Withdraw Rewards transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw Rewards transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw Rewards transaction has Failed");
        });
    },
    isRegisterAllowed(unlockTime) {
      return new Date().getTime() < unlockTime * 1000;
    },
    cannotWithdrawCarn(lastWithdrawalDay, endRewardDay) {
      // console.log(typeof lastWithdrawalDay )
      // console.log(typeof this.currentDay )
      return Number(this.currentDay) <= Number(lastWithdrawalDay) || Number(lastWithdrawalDay) >= Number(endRewardDay);
    },
    geturi(nfturi) {
      if (nfturi.includes("ipfs://")) {
        console.log("converting: ", nfturi)
        //this is meant to account for the case of korkeys bulk mint plsb lock nft site...he uplods to ipfs differently
        nfturi = nfturi.replace("ipfs://", "");
        return `https://carn.infura-ipfs.io/ipfs/${nfturi}`;
      }
      let arr = nfturi.split("/");
      let ch = arr[arr.length - 1];
      return `https://carn.infura-ipfs.io/ipfs/${ch}`;
    },
  },
  computed: {
    NftsThatCanBeRegistered() {
      return this.nfts.filter(nft => this.isRegisterAllowed(nft.unlockTime) && nft.id && !nft.isRegistered);
    },
    shouldDisableBulkRegistration() {
      // console.log("this.NftsThatCanBeRegistered.length: ",this.NftsThatCanBeRegistered.length)
      return this.NftsThatCanBeRegistered.length === 0;
    },
    NftsThatCanBeWithdrawed() {
      return this.nfts.filter(nft => !this.cannotWithdrawCarn(nft.withdrawalDay, nft.endRewardDay) && nft.id && nft.isRegistered);
    },
    shouldDisableBulkWithdrawal() {
      // console.log("this.NftsThatCanBeRegistered.length: ",this.NftsThatCanBeWithdrawed.length)
      return this.NftsThatCanBeWithdrawed.length === 0;
    },
    notEnoughHexRewardsAvailable() {
      return this.HEXInContract < 200 * 1e8
    }
  },


  watch: {
    getUserAddress() {
        if (this.getUserAddress)
          this.readValues();
    },
  },
};
</script>
<style>
.nft_img {
  width: 225px;
  height: 225px;
  margin-right: 20px;
  border-radius: 10px;
  border: 1px solid grey;
}
.v-main.FreeHex .v-main__wrap {
  background-image: url("../assets/hexlogos2.jpg");
  background-size: 200%;
  background-position: center;

}

.disabledSection {
  pointer-events: none;
  opacity: 0.4
}

</style>
