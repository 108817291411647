<template>
  <div>
    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-2">
        <v-card class="mx-auto">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/calendar.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ currentDay }} <br />
                Current Day
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <v-card
          class="mx-auto"
          :href="ETHERSCAN_URL_BASE + 'address/' + PULSE_BITCOIN_LOCK_ADDRESS"
          target="_blank"
        >
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/logo_gold_lock.jpeg" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ nfts.length }} <br />
                PulseBitcoinLock NFTs
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/carn_logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(CARNBalance) }} <br />
                Your CARN Balance
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/carn_logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(CARNInContract) }} <br />
                CARN Rewards Available
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="cyan lighten-1">
              <img src="@/assets/carn_logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(totalFreeCarnWithdrawn) }} <br />
                Total CARN Withdrawn
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>

    <v-row v-show="getUserAddress && nfts.length" class="justify-center">
      <div class="col-12 col-sm-6 col-md-5">
        <v-card class="mx-auto text-center">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="endOfDay">0 Hours 0 Minutes 0 Seconds</h3>
              </v-list-item-title>
              <h4 class="font-weight-regular">
                until the next days CARN Rewards will be available for withdrawal.
              </h4>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>
    <v-row v-show="this.walletChainID == 369">
      <div class="col-12"> <!-- v-bind:class="'disabledSection'">-->
        <v-card class="pa-5">
          <h1 class="grey--text text--darken-2">Maintenance</h1>
          Carn rewards on PulseChain is undergoing some maintenance
        </v-card>
      </div>

    </v-row>
    <v-row v-show="this.walletChainID != 369">
      <div class="col-12"> <!-- v-bind:class="'disabledSection'">-->
        <v-card class="pa-5">
          <h1 class="grey--text text--darken-2">CARN Rewards


            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <img
                    width="18px"
                    src="@/assets/info.webp"
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <span>
          <ul>
            * To get CARN Rewards, you must register your PulseBitcoinLock NFT for rewards before the Unlock Time
          </ul>
          <ul>
            * You only get rewards starting the day you register.
          </ul>
          <ul>
            * Upon Registering you'll get your days worth of rewards and when
            the countdown timer hits zero, you can claim again.
          </ul>
          <ul>
            * The first 1343 PulseBitcoinLock NFT's that get created will get a
            special bonus upon registering (that is any PulseBitcoinLock NFT
            with IDs 0-1343)
          </ul>
          <ul>
            * You dont have to claim every day. You can wait as many days as you want and then
            claim your $CARN (similar to $HDRN except without the borrowing!)
          </ul>
          <ul>
            * As long as your NFT is registered before the Unlock Time, you can still collect your CARN Rewards even after the Unlock
            Time is over.
          </ul>
          <ul>
            * The amount of CARN Rewards you can collect every day is equal to
            (amount_locked_up) * (days_locked_up) * (0.0000015)
          </ul>
          <ul>
            * For that formula, the amount_locked_up and the days_locked_up are both capped at 1000. Meaning,
            an NFT that has 5000 PLSB locked up for 5000 days will still get 1.5
            CARN Rewards per day.
          </ul>
              </span>
            </v-tooltip>

          </h1>
          <v-divider class="my-6"></v-divider>
          <template v-if="!getUserAddress">
            <div class="d-flex justify-center">
              <p class="mt-5 mb-10 text-center">
                Connect your wallet to see your NFT's
              </p>
            </div>
          </template>
          <template v-else-if="isNFTLoading">
            <div class="d-flex justify-center my-10">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </template>
          <template v-else-if="!nfts.length">
            <div class="d-flex justify-center">
              <p class="mt-5 text-center">
                HEX Rewards is for PulseBitcoinLock NFT Holders only.
                <br> Head on over to <a href="https://PulseBitcoinLock.app/" target="_blank"><strong>PulseBitcoinLock.app</strong></a>
                and Lock Up some PulseBitcoin, then come back here to get CARN Rewards.
              </p>
            </div>
          </template>
          <div class="row">
            <template v-for="(nft, i) in nfts">
              <div class="col-12" :key="i">
                <div class="d-block d-md-flex text-center text-md-left">
                  <div>
                    <a href="http://pulsebitcoinlock.app" target="_blank"><img class="nft_img" :src="nft.image" /></a>
                  </div>
                  <div>
                    <div class="mb-1"><b>Id: </b>{{ nft.id }}</div>
                    <div class="mb-1">
                      <b>Total Lock Time: </b>{{ HHMMSS(nft.lockTime) }}
                    </div>
                    <div class="mb-1">
                      <b>Unlock Time (must register before this time): </b
                      >{{ humanDate(nft.unlockTime) }}
                    </div>
                    <div class="mb-1">
                      <b>Locked Amount: </b
                      >{{ twelveFormat(nft.lockedAmount) }} PLSB
                    </div>
                    <div class="mb-1" v-if="nft.transferable">
                      <b>This NFT is Transferable</b>
                    </div>
                    <div class="mb-1" v-if="!nft.transferable">
                      <b>This NFT is Non-Transferable</b>
                    </div>

                    <div
                      v-show="
                        isRegisterAllowed(nft.unlockTime) && nft.isRegistered
                      "
                    >
                      <div class="mb-1">
                        <b>Daily Reward: </b
                        >{{ twelveFormat(nft.reward, 2) }} $CARN
                      </div>
                      <div class="mb-1">
                        <b>Days Since Last Withdrawal: </b
                        >{{ currentDay - nft.withdrawalDay }} Days
                      </div>
                      <div class="mb-1">
                        <b>Rewards End On Day: </b>{{ nft.endRewardDay }}
                      </div>

                    </div>
                    <template v-if="nft.isRegistered">
                      <v-btn
                        v-if=cannotWithdrawCarn(nft.withdrawalDay)
                        small
                        color="mt-1 yellow"
                        class="px-7"
                        :disabled="true"
                        >Come back tomorrow to withdraw more $CARN
                      </v-btn>
                      <v-btn
                        v-else
                        small
                        color="mt-1 yellow"
                        class="px-7"
                        @click="onWithdraw(nft.id)"
                        >{{
                          `Withdraw ${
                            twelveFormat((currentDay - nft.withdrawalDay) * nft.reward, 2)
                          } Free $CARN!!`
                        }}
                      </v-btn>
                      <small> </small>
                    </template>
                    <v-btn
                      v-else
                      small
                      color="mt-1 yellow"
                      class="px-7"
                      :disabled="!isRegisterAllowed(nft.unlockTime)"
                      @click="onRegister(nft.id)"
                    >
                      {{
                        !isRegisterAllowed(nft.unlockTime)
                          ? "Cannot Register - NFT expired"
                          : "Register NFT For Free $CARN"
                      }}
                    </v-btn>
                  </div>
                </div>
                <v-divider class="my-3"></v-divider>
              </div>
            </template>
          </div>
        </v-card>
      </div>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "FreeCarn",
  data() {
    return {
      nfts: [],
      totalNFTS: 0,
      currentDay: 0,
      CARNBalance: 0,
      CARNInContract: 0,
      isNFTLoading: true,
      clearIntervalX: null,
      totalFreeCarnWithdrawn: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalX);
  },
  async mounted() {
    if (this.getUserAddress) 
      this.readValues();
    
  },
  methods: {
    compareValues(key) {
      return (a, b) => {
        let comparison = 0;
        if (a[key] > b[key]) {
          comparison = 1;
        } else if (a[key] < b[key]) {
          comparison = -1;
        }
        return comparison;
      };
    },
    readValues() {
      console.log('readvalues')
      Promise.all([
        this.getNftRewardsInstance.methods.currentDay().call(),
        this.getCARNInstance.methods.balanceOf(this.getUserAddress).call(),
        this.getCARNInstance.methods.balanceOf(this.NFT_REWARDS_ADDRESS).call(),
          this.getNftRewardsInstance.methods.totalFreeCarnWithdrawn().call(),
      ]).then(([currentDay, CARNBalance, CARNInContract, totalFreeCarnWithdrawn]) => {
        this.currentDay = currentDay;
        this.CARNBalance = CARNBalance;
        this.CARNInContract = CARNInContract;
        this.totalFreeCarnWithdrawn = totalFreeCarnWithdrawn;
        console.log("end read values")


        clearInterval(this.clearIntervalX);
        this.counter();
        this.getUserNFTs();
      });
      console.log("end read values")
    },
    counter() {
      const endOfDay = new Date().setUTCHours(23, 59, 59, 999);
      this.clearIntervalX = setInterval(function () {
        let now = new Date().getTime();
        // console.log("^^^^^^^^^^^^^^^^^^now:", now)
        // console.log("^^^^^^^^^^^^^endOfDay:", endOfDay)
        const distance = endOfDay - now;
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalX);
          document.getElementById(
            "endOfDay"
          ).innerHTML = `0 Hours 0 Minutes 0 Seconds`;
        } else {
          document.getElementById(
            "endOfDay"
          ).innerHTML = `${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
          // let btns = document.getElementsByClassName("cannotWithdrawCarn");
          // if (btns && btns.length) {
          //   Array.prototype.forEach.call(btns, (el) => {
          //     el.innerHTML = `
          //     Come back in ${hours} Hours ${minutes} Minutes ${seconds} Seconds to withdraw more $CARN`;
          //   });
          // }
        }
      }, 1000);
    },
    getUserNFTs() {
      console.log("get user nfts")
      this.nfts = [];
      this.isNFTLoading = true;
      let URL = `https://api.thegraph.com/subgraphs/name/korkey128k/pulsebitcoinlocknft`; // use this for mainnet
      //let URL = `https://api.studio.thegraph.com/query/29707/pulsebitcoinlock/1.2.0`;
      //let URL = `https://api.studio.thegraph.com/query/29707/pulsebitcoinlock/1.2.1`;
      if(this.walletChainID == 369) {
        URL = `https://pulse.graph.korkey.tech/subgraphs/name/pulsebitcoinlock`; // use this for mainnet
      }
      const query = `
                    {
                      owner(id:"${this.getUserAddress.toLowerCase()}") {
                        id
                        nfts {
                          id
                          minter
                          lockedAmount
                          tipAmount
                          lockTime
                          unlockTime
                          uri
                          tokenId
                          timestamp
                          burner
                          burned
                          burnTime
                          transferable
                        }
                      }
                    }
                    `;
      const headers = {
        "Content-Type": "application/json",
      };
      console.log('hello');

      axios
        .post(URL, { query: query }, { headers: headers })
        .then((response) => {
          let data = response.data.data;
          let nfts = data.owner ? data.owner.nfts : [];
          console.log(nfts);
          if (nfts.length) {
            nfts.forEach(async (nft, i) => {
              let uri = this.geturi(nft.uri);
              axios
                .get(uri)
                .then((response) => {
                  nft.name = response.data.name;
                  nft.image = this.geturi(response.data.image);
                  Promise.all([
                    this.getNftRewardsInstance.methods
                      .tokenIdsToRegistered(nft.id)
                      .call(),
                    this.getNftRewardsInstance.methods
                      .tokenIdsToDailyRewardAmount(nft.id)
                      .call(),
                    this.getNftRewardsInstance.methods
                      .tokenIdsToLastWithdrawalDay(nft.id)
                      .call(),
                    this.getNftRewardsInstance.methods
                      .tokenIdsToEndRewardsDay(nft.id)
                      .call(),
                  ]).then(
                    ([isRegistered, reward, withdrawalDay, endRewardDay]) => {
                      console.log("isRegistered: ", isRegistered);
                      console.log("REWARD: ", reward);
                      console.log("WithdrawalDay: ", withdrawalDay);
                      nft.reward = reward;
                      nft.isRegistered = isRegistered;
                      nft.withdrawalDay = withdrawalDay;
                      nft.endRewardDay = endRewardDay;
                      this.nfts.push(nft);
                      if (i + 1 === nfts.length) {
                        this.nfts.sort(this.compareValues("id"));
                      }
                      this.isNFTLoading = false;
                    }
                  );
                })
                .catch((error) => {
                  console.log(error);
                  this.isNFTLoading = false;
                });
            });
          } else {
            this.isNFTLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isNFTLoading = false;
        });
    },

    onRegister(tokenId) {
      this.SET_LOADING(true);
      this.getNftRewardsInstance.methods
        .registerNftForRewards(tokenId)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Register transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Register transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Register transaction has Failed");
        });
    },

    onWithdraw(tokenId) {
      this.SET_LOADING(true);
      this.getNftRewardsInstance.methods
        .withdrawRewards(tokenId)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Withdraw Rewards transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw Rewards transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw Rewards transaction has Failed");
        });
    },
    isRegisterAllowed(unlockTime) {
      return new Date().getTime() < unlockTime * 1000;
    },
    cannotWithdrawCarn(lastWithdrawalDay) {
      console.log(typeof lastWithdrawalDay )
      console.log(typeof this.currentDay )
      return Number(this.currentDay) <= Number(lastWithdrawalDay);
    },
    geturi(nfturi) {
      console.log("nfturi", nfturi);
      if (nfturi.includes("ipfs://")) {
        //this is meant to account for the case of korkeys bulk mint plsb lock nft site...he uplods to ipfs differently
        nfturi = nfturi.replace("ipfs://", "");
        return `https://carn.infura-ipfs.io/ipfs/${nfturi}`;
      }
      let arr = nfturi.split("/");
      let ch = arr[arr.length - 1];
      return `https://carn.infura-ipfs.io/ipfs/${ch}`;
    },
  },
  computed: {},
  watch: {
    getUserAddress() {
        if (this.getUserAddress)
          this.readValues();
    },
  },
};
</script>
<style>
.nft_img {
  width: 225px;
  height: 225px;
  margin-right: 20px;
  border-radius: 10px;
  border: 1px solid grey;
}



 .v-main.FreeCarn .v-main__wrap {
   background-image: url("../assets/hexlogos2.jpg");
   background-size: 200%;
   background-position: center;
 }
.disabledSection {
  pointer-events: none;
  opacity: 0.4
}

</style>
