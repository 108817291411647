<template>
  <div class="stake_card_body">
    <v-row class="justify-center">
      <div class="col-12 col-sm-9 col-xl-5">
        <v-card class="pa-5">
          <div class="d-flex justify-space-between align-center">
            <h1 class="grey--text text--darken-2">
              Stake Your PLS at the PulseChain Carnival
            </h1>
          </div>
          <v-divider class="mt-0 my-7"></v-divider>
          <div class="d-flex justify-end mb-4">
            <small>
              Available Amount:
              <strong
                >{{ formator(tokenBalance, 2) }} {{ TOKEN_SYMBOL }}</strong
              >
            </small>
          </div>
          <v-row>
            <div class="col-6 py-1">
              <v-text-field
                outlined
                type="number"
                placeholder="1"
                v-model.number="lockupAmount"
                :label="'Number of ' + TOKEN_SYMBOL + ' tokens to lock up'"
              >
                <template v-slot:append>
                  <v-btn
                    text
                    color="primary"
                    small
                    :disabled="!getUserAddress"
                    @click="
                      lockupAmount = tokenBalance / TOKEN_DECIMAL_CONVERSION
                    "
                  >
                    MAX
                  </v-btn>
                </template>
              </v-text-field>
            </div>
            <div class="col-6 py-1">
              <v-text-field
                outlined
                type="number"
                placeholder="Example: 1, 5555 or 10000!"
                v-model.number="lockupDays"
                @keypress="isNumber($event)"
                label="Number of days to lock up"
              >
              </v-text-field>
            </div>
            <div class="col-6 py-1">
              <v-file-input
                outlined
                prepend-icon=""
                append-icon="mdi-paperclip"
                label="Upload an image for your NFT"
                placeholder="350 x 350 image is recommended"
                @change="onSelect"
              ></v-file-input>
            </div>
            <div class="col-6 py-1">
              <v-text-field
                outlined
                type="text"
                v-model.number="lockupTip"
                :label="'Optional Developer Tip (in  ' + TOKEN_SYMBOL + ')'"
                placeholder="Example: 0"
              >
              </v-text-field>
            </div>
            <div class="col-12 py-1 text-center">  
              <img v-if="imageSrc" class="imageSrc" :src="imageSrc" width="350px" height="350px" />
            </div>
            <div class="col-12 py-1">
              <v-textarea
                outlined
                label="Enter a statement for your NFT"
                v-model="lockupDescription"
                placeholder='Example: "Not your keys, not your coins" or "A failure to plan, is a plan for failure"...You can put a poem, lyrics to your favorite song, an inspiring quote, a goal of yours, what you plan on doing with these coins once you Burn your NFT etc BE CREATIVE and remember this statement is immutable.'
              ></v-textarea>
            </div>
            <div class="col-6 py-1">
              <v-radio-group v-model="transferable" mandatory>
                <v-radio
                  label="Non-Transferable (until Lock Up period ends)"
                  :value="false"
                ></v-radio>
                <v-radio
                  label="Transferable (even before Lock Up period ends)"
                  :value="true"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="col-6 py-1">
              <v-checkbox
                v-model="mint_to_different_address"
                label="Mint to a different address?"
              ></v-checkbox>
              <v-text-field
                v-if="mint_to_different_address"
                outlined
                type="text"
                v-model="mint_to_address"
                label="Enter Address"
                placeholder="Paste the address that you want the NFT to be minted to"
              >
              </v-text-field>
            </div>
          </v-row>

          <v-btn
            block
            x-large
            color="yellow"
            class="mt-8"
            @click="onApprove"
            :disabled="!getUserAddress || isBtnLoading"
          >
            {{
              isBtnLoading
                ? "Loading.."
                : isApproved
                ? `Lock up ${lockupAmount} ${TOKEN_SYMBOL}
              for ${lockupDays | 0} ${isPlural(lockupDays | 0)}
              and Mint my ${
                this.transferable ? "Transferable" : "Non-Transferable"
              } NFT ${mintToText()}`
                : "Approve PLSB"
            }}
          </v-btn>
          <v-divider class="mt-10"></v-divider>
          <small class="d-block text-right"
            >If you'd like to tip in USDC, ETH, ASIC, PLSB, HEX, HDRN etc,
            donate here --->
            <v-btn
              small
              text
              class="py-0 pr-0"
              @click="copy_to_clipboard(TIP_ADDRESS)"
            >
              &#128203;{{ addrTruncation(TIP_ADDRESS) }}
            </v-btn>
          </small>
        </v-card>
      </div>
    </v-row>
    <v-row class="justify-center">
      <div class="col-12">
        <v-card class="pa-5" style="max-width: 1080px; margin: auto">
          <div class="row mt-1">
            <div class="col-12">
              <div class="d-flex justify-space-between align-items-center">
                <h1 class="grey--text text--darken-2">Your NFTs</h1>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                      Sort
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="cursor-pointer">
                      <v-list-item-title
                        @click="nfts.sort(compareValues('amounts'))"
                        >Amount</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item class="cursor-pointer">
                      <v-list-item-title
                        @click="nfts.sort(compareValues('totalTime'))"
                        >Total Lock Time</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item class="cursor-pointer">
                      <v-list-item-title
                        @click="nfts.sort(compareValues('timeLeft'))"
                        >Time Remaining</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-divider class="mb-2"></v-divider>
            </div>
            <div v-if="nftLoader" class="col-12">
              <div class="mx-auto my-5 loader"></div>
            </div>
            <template v-else-if="!getUserAddress">
              <p class="mx-auto text-muted my-5">
                Connect Your Wallet to see your NFTs here
              </p>
            </template>
            <template v-else-if="!nfts.length">
              <p class="mx-auto text-muted my-5">No NFT to show</p>
            </template>
            <template v-for="(nft, i) in paginatedNFTs">
              <div class="col-12" :key="i">
                <div class="d-block d-md-flex text-center text-md-left">
                  <div class="mr-5">
                    <a
                      :href="`${OPENSEA_URL_BASE}${CONTRACT_ADDRESS}/${nft.token_id}`"
                      target="_blank"
                    >
                      <vue-load-image>
                        <img slot="image" :src="nft.metadata.image" />
                        <img slot="preloader" src="@/assets/default.jpg" />
                        <img slot="error" src="@/assets/default.jpg" />
                      </vue-load-image>
                    </a>
                  </div>
                  <div>
                    <h3
                      class="card-title my-0"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="formator(nft.amounts, 10)"
                    >
                      {{ formator(nft.amounts, 2) }} {{ TOKEN_SYMBOL }}
                      <img
                        v-if="nft.transferable"
                        src="@/assets/money.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-if="!nft.transferable"
                        src="@/assets/diamond.png"
                        width="20"
                        height="20"
                      />
                    </h3>
                    <b>NFT ID: </b
                    ><a
                      :href="`${ETHERSCAN_URL_BASE}token/${CONTRACT_ADDRESS}?a=${nft.token_id}`"
                      target="_blank"
                      >{{ nft.token_id }}</a
                    >
                    <div>
                      <b> Description: </b>
                      {{ nft.description }}
                    </div>
                    <div class="d-flex justify-space-between">
                      <div v-if="nft.isBurnAble">
                        <v-btn
                          small
                          class="px-5 mt-3"
                          color="yellow"
                          @click="onBurn(nft.id.tokenId, nft.amounts)"
                        >
                          BURN
                        </v-btn>
                      </div>
                      <div v-else>
                        <div>
                          <b> Time Remaining: </b>
                          <span class="text-blue">{{
                            secondstoDays(nft.timeLeft)
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <v-divider class="mt-3"></v-divider>
              </div>
            </template>
            <div class="col-12 text-center">
              <jw-pagination
                :pageSize="6"
                :maxPages="3"
                :items="this.nfts"
                @changePage="onChangePage"
              ></jw-pagination>
            </div>
          </div>
        </v-card>
      </div>
    </v-row>
  </div>
</template>

<script>
import copy from "copy-to-clipboard";
export default {
  name: "PulseBitcoinLockApp",
  data() {
    return {
      nfts: [],
      order: "desc",
      nftLoader: false,
      paginatedNFTs: [],

      tokenBalance: 0,
      ipfsInstance: null,
      lockupAmount: 1,
      lockupDays: null,
      lockupUri: null,
      lockupTip: null,
      imageSrc: null,
      lockupImage: null,
      lockupDescription: null,
      mint_to_address: null,
      mint_to_different_address: false,
      transferable: false,
      isApproved: false,
      isBtnLoading: false,
    };
  },
  async mounted() {
    if (this.getUserAddress) {
      this.getData();
      this.readValues();
    }
  },
  methods: {
    mintToText() {
      if (this.mint_to_different_address) {
        return `To ${this.mint_to_address}`;
      } else {
        return "";
      }
    },

    copy_to_clipboard(thing_to_copy) {
      copy(thing_to_copy);
      this.$toasted.show(`Copied ${thing_to_copy} to clipboard`);
    },

    onChangePage(pageOfItems) {
      this.paginatedNFTs = pageOfItems;
    },

    compareValues(key) {
      this.order = this.order === "asc" ? "desc" : "asc";
      return (a, b) => {
        let comparison = a[key] - b[key];
        return this.order === "asc" ? comparison : comparison * -1;
      };
    },

    async getData() {
      this.nfts = [];
      this.nftLoader = true;
      let list = await this.fetchNFTs();
      let now = new Date().getTime() / 1000;
      if (!list.length) this.nftLoader = false;
      list.forEach((item, index) => {
        Promise.all([
          this.getContractInstance.methods.ownerOf(item.id.tokenId).call(),
          this.getContractInstance.methods.lockTime(item.id.tokenId).call(),
          this.getContractInstance.methods
            .tokenIdsToAmounts(item.id.tokenId)
            .call(),
          this.getContractInstance.methods
            .tokenIdsToTransferable(item.id.tokenId)
            .call(),
        ]).then(([owenr, lockTime, Amounts, transferable]) => {
          item.owenr = owenr;
          item.amounts = Amounts;
          item.token_id = this.getWeb3.utils.hexToNumber(item.id.tokenId);

          // totalTime
          let start = new Date(item.timeLastUpdated).getTime() / 1000;
          item.totalTime = Number(lockTime) - Number(start);

          // lockTime
          if (lockTime > now) item.isBurnAble = false;
          else item.isBurnAble = true;

          item.timeLeft = Number(lockTime) - Number(now);
          item.transferable = transferable;

          this.nfts.push(item);
          this.nfts.sort(this.compareValues("amounts")).reverse();
          if (list.length === index + 1) {
            this.nftLoader = false;
          }
        });
      });
    },

    onApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      if (this.isApproved) {
        this.onLockUp();
        return;
      }
      this.isBtnLoading = true;
      this.getTokenInstance.methods
        .approve(this.CONTRACT_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show(
            "In the process of approving the smart contract to interact with your PLSB"
          );
        })
        .on("receipt", (receipt) => {
          this.isBtnLoading = false;
          this.readValues();
          console.log(receipt);
          this.$toasted.show(
            "The smart contract is now approved to interact with your PLSB!"
          );
        })
        .on("error", (error, receipt) => {
          this.isBtnLoading = false;
          console.log(error, receipt);
          this.$toasted.show(
            "The transaction to approve the smart contract to interact with your PLSB failed"
          );
        });
    },

    async onLockUp() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect you wallet first!");
        return;
      }
      if (!this.lockupAmount) {
        this.$toasted.show("Enter lockup amount");
        return;
      } else if (!this.lockupImage) {
        this.$toasted.show("Upload NFT image");
        return;
      } else if (!this.lockupDescription) {
        this.$toasted.show("Write NFT description");
        return;
      } else if (this.lockupTip === null || this.lockupTip === "") {
        this.$toasted.show("Enter developer tip amount");
        return;
      } else if (
        this.lockupAmount * this.TOKEN_DECIMAL_CONVERSION >
        this.tokenBalance
      ) {
        this.$toasted.show("Insufficient balance");
        return;
      } else if (
        this.mint_to_different_address &&
        !this.getWeb3.utils.isAddress(this.mint_to_address)
      ) {
        this.$toasted.show(
          `Non existent 'Mint To Address': ${this.mint_to_address}`
        );
        return;
      }
      try {
        this.isBtnLoading = true;
        await this.submitToIPFS(
          this.lockupAmount,
          this.lockupDays,
          this.lockupTip,
          this.transferable
        );
        let amount = this.getWeb3.utils.toWei(
          this.lockupAmount.toString(),
          this.UNIT_CONVERSION
        );
        let lockupTip = this.getWeb3.utils.toWei(
          this.lockupTip.toString(),
          this.UNIT_CONVERSION
        );
        let lockupSeconds = Number(this.lockupDays) * 86400;
        let transferable = this.transferable;
        let mintToAddress;
        if (this.mint_to_different_address) {
          mintToAddress = this.mint_to_address;
        } else {
          mintToAddress = this.getUserAddress;
        }
        console.log(amount);
        console.log(lockupTip);
        this.getContractInstance.methods
          .lockUpAndMint(
            amount,
            lockupTip,
            lockupSeconds,
            transferable,
            mintToAddress,
            this.lockupUri
          )
          .send({
            from: this.getUserAddress,
          })
          .on("transactionHash", (hash) => {
            console.log(hash);
            this.clearInputs();
            this.$toasted.show(
              "Your PLSB is being locked up, and an NFT is being minted"
            );
          })
          .on("receipt", async (receipt) => {
            console.log(receipt);
            setTimeout(() => {
              this.getData();
            }, 3000);
            this.readValues();
            this.clearInputs();
            this.isBtnLoading = false;
            this.$toasted.show(
              "Your PLSB has been locked up, and an NFT has been minted"
            );
          })
          .on("error", (error, receipt) => {
            this.isBtnLoading = false;
            console.log(error, receipt);
            this.$toasted.show("Lock up transaction has Failed");
          });
      } catch (error) {
        this.isBtnLoading = false;
        console.log("Error:", error);
        this.$toasted.show("Invalid input value");
      }
    },

    readValues() {
      Promise.all([
        this.getTokenInstance.methods.balanceOf(this.getUserAddress).call(),
        this.getTokenInstance.methods
          .allowance(this.getUserAddress, this.CONTRACT_ADDRESS)
          .call(),
      ]).then(([balance, allowance]) => {
        this.isApproved = allowance == 0 ? false : true;
        this.tokenBalance = balance;
      });
    },

    onSelect(file) {
      this.imageSrc = null;
      this.lockupImage = null;
      if (!file) {
        this.$toasted.show("Select an image");
        return;
      }
      this.lockupImage = file;
      var filetypes = file["type"].split("/");
      var filetype = filetypes[0];
      if (filetype == "image") {
        var reader = new FileReader();

        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      } else if (filetype == "video") {
        console.log("Only Images are allowed");
        this.$toasted.show("Only Images are allowed");
      }
    },

    async submitToIPFS(amount, days, tip, isTransferable) {
      const auth =
        "Basic " +
        window.buffer.Buffer.from(
          this.INFURA_ID + ":" + this.INFURA_SECRET_KEY
        ).toString("base64");
      this.ipfsInstance = window.IpfsHttpClient.create({
        host: "ipfs.infura.io",
        port: 5001,
        protocol: "https",
        headers: {
          authorization: auth,
        },
      });
      let data = {
        name: `${amount} ${
          this.TOKEN_SYMBOL
        } locked for ${days} ${this.isPlural(days)}`,
        image: null,
        description: this.lockupDescription,
        attributes: [
          {
            display_type: "number",
            trait_type: `Amount of ${this.TOKEN_SYMBOL} Locked Up`,
            value: amount,
          },
          {
            display_type: "number",
            trait_type: `Total Lock Up Days`,
            value: days,
          },
          {
            display_type: "number",
            trait_type: `Dev Tip (in ${this.TOKEN_SYMBOL})`,
            value: tip,
          },
          {
            value: `${isTransferable ? "Transferable" : "Non-Transferable"}`,
          },
        ],
      };

      if (this.lockupImage) {
        let pic = await this.ipfsInstance.add(this.lockupImage, {
          progress: (prog) => console.log(`received: ${prog}`),
        });
        let image = `https://ipfs.io/ipfs/${pic.path}`;
        data.image = image;
      }

      console.log("data:", data);

      let data_path = await this.ipfsInstance.add(JSON.stringify(data), {
        progress: (prog) => console.log(`received: ${prog}`),
      });

      this.lockupUri = `https://ipfs.io/ipfs/${data_path.path}`;
    },

    async onBurn(id, amounts) {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect you wallet first!");
        return;
      }

      this.getContractInstance.methods
        .burn(id)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show(
            `Your NFT is being Burnt, and your ${this.TOKEN_SYMBOL} tokens are being returned to your wallet, great job HODLing!`
          );
        })
        .on("receipt", async (receipt) => {
          console.log(receipt);
          this.getData();
          this.readValues();
          this.$toasted.show(
            `${this.formator(amounts, 2)} ${
              this.TOKEN_SYMBOL
            } has been unlocked and returned to your wallet`
          );
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.$toasted.show("Burn transaction Failed");
        });
    },

    clearInputs() {
      this.ipfsInstance = null;
      this.lockupUri = null;
      this.imageSrc = null;
      this.lockupAmount = 1;
      this.lockupDays = 0;
      this.lockupImage = null;
      this.lockupDescription = null;
      this.lockupTip = null;
      this.mint_to_address = null;
      this.mint_to_different_address = false;
      this.transferable = false;
      this.isBtnLoading = false;
      this.nfts = [];
    },
  },
  watch: {
    async getUserAddress() {
      this.getData();
      this.readValues();
    },
  },
};
</script>
<style>
@import "../styles/PulseBitcoinLockApp.css";
.v-main.PulseBitcoinLockApp .v-main__wrap {
  background-image: url("../assets/LogoTransparent.png");

  background-size: 150%;
  background-position: center;
}

.stake_card_body .v-text-field__details {
  display: none;
}
.stake_card_body .v-radio .v-label,
.stake_card_body .v-input--checkbox .v-label {
  font-size: 13px;
}
.stake_card_body .v-input--selection-controls {
  margin-top: 0;
}
.stake_card_body  .imageSrc{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
}
</style>
