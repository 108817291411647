import config from "@/config/config";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      CHAIN_ID: config.CHAIN_ID,
      NETWORK: config.NETWORK,
      INFURA_ID: config.INFURA_ID,
      ALCHEMY_API_KEY: config.ALCHEMY_API_KEY,
      INFURA_SECRET_KEY: config.INFURA_SECRET_KEY,
      OPENSEA_URL_BASE: config.OPENSEA_URL_BASE,
      ETHERSCAN_URL_BASE: config.ETHERSCAN_URL_BASE,
      ALCHEMY_URL_BASE_NFT: config.ALCHEMY_URL_BASE_NFT,
      ALCHEMY_URL_BASE: config.ALCHEMY_URL_BASE,
      CARN_ADDRESS: config.CARN_ADDRESS,
      PLSD_ADDRESS: config.PLSD_ADDRESS,
      PLSB_ADDRESS: config.PLSB_ADDRESS,
      ASIC_ADDRESS: config.ASIC_ADDRESS,
      USDC_ADDRESS: config.USDC_ADDRESS,
      HEX_ADDRESS: config.HEX_ADDRESS,
      CHAINLINK_ADDRESS: config.CHAINLINK_ADDRESS,
      STAKING_ADDRESS: config.STAKING_ADDRESS,
      WAATCA_ADDRESS: config.WAATCA_ADDRESS,
      NFT_REWARDS_ADDRESS: config.NFT_REWARDS_ADDRESS,
      NFT_HEX_REWARDS_ADDRESS: config.NFT_HEX_REWARDS_ADDRESS,
      BURN_ADDRESS: config.BURN_ADDRESS,
      ASIC_MINER_ADDRESS: config.ASIC_MINER_ADDRESS,
      HEX_STAKER_ADDRESS: config.HEX_STAKER_ADDRESS,
      PULSE_BITCOIN_LOCK_ADDRESS: config.PULSE_BITCOIN_LOCK_ADDRESS,
      CARN_FAUCET_ADDRESS: config.CARN_FAUCET_ADDRESS,
      PLSD_FAUCET_ADDRESS: config.PLSD_FAUCET_ADDRESS,
      PLSB_FAUCET_ADDRESS: config.PLSB_FAUCET_ADDRESS,
      ASIC_FAUCET_ADDRESS: config.ASIC_FAUCET_ADDRESS,
      USDC_FAUCET_ADDRESS: config.USDC_FAUCET_ADDRESS,
      HEX_FAUCET_ADDRESS: config.HEX_FAUCET_ADDRESS,
      PLSB_HOUR_LOTTERY_ADDRESS: config.PLSB_HOUR_LOTTERY_ADDRESS,
      ASIC_HOUR_LOTTERY_ADDRESS: config.ASIC_HOUR_LOTTERY_ADDRESS,
      PLSD_HOUR_LOTTERY_ADDRESS: config.PLSD_HOUR_LOTTERY_ADDRESS,
      CARN_HOUR_LOTTERY_ADDRESS: config.CARN_HOUR_LOTTERY_ADDRESS,
      HEX_HOUR_LOTTERY_ADDRESS: config.HEX_HOUR_LOTTERY_ADDRESS,
      USDC_HOUR_LOTTERY_ADDRESS: config.USDC_HOUR_LOTTERY_ADDRESS,
      PLSB_WEEK_LOTTERY_ADDRESS: config.PLSB_WEEK_LOTTERY_ADDRESS,
      ASIC_WEEK_LOTTERY_ADDRESS: config.ASIC_WEEK_LOTTERY_ADDRESS,
      PLSD_WEEK_LOTTERY_ADDRESS: config.PLSD_WEEK_LOTTERY_ADDRESS,
      CARN_WEEK_LOTTERY_ADDRESS: config.CARN_WEEK_LOTTERY_ADDRESS,
      HEX_WEEK_LOTTERY_ADDRESS: config.HEX_WEEK_LOTTERY_ADDRESS,
      USDC_WEEK_LOTTERY_ADDRESS: config.USDC_WEEK_LOTTERY_ADDRESS,
      PLSB_MONTH_LOTTERY_ADDRESS: config.PLSB_MONTH_LOTTERY_ADDRESS,
      ASIC_MONTH_LOTTERY_ADDRESS: config.ASIC_MONTH_LOTTERY_ADDRESS,
      PLSD_MONTH_LOTTERY_ADDRESS: config.PLSD_MONTH_LOTTERY_ADDRESS,
      CARN_MONTH_LOTTERY_ADDRESS: config.CARN_MONTH_LOTTERY_ADDRESS,
      HEX_MONTH_LOTTERY_ADDRESS: config.HEX_MONTH_LOTTERY_ADDRESS,
      USDC_MONTH_LOTTERY_ADDRESS: config.USDC_MONTH_LOTTERY_ADDRESS,
      TOKEN_SYMBOL: config.TOKEN_SYMBOL,
      TOKEN_ADDRESS: config.TOKEN_ADDRESS,
      CONTRACT_ADDRESS: config.CONTRACT_ADDRESS,
      UNIT_CONVERSION: config.UNIT_CONVERSION,
      TOKEN_DECIMAL_CONVERSION: config.TOKEN_DECIMAL_CONVERSION,
      TIP_ADDRESS: config.TIP_ADDRESS,
      TOKEN_ABI: config.TOKEN_ABI,
      CONTRACT_ABI: config.CONTRACT_ABI,
      ...mapActions("wallet", [
        "SET_LOADING",
        "SET_WEB3",
        "SET_WALLETCHAINID",
        "SET_USER_ADDRESS",
        "SET_CARN_INSTANCE",
        "SET_PLSD_INSTANCE",
        "SET_PLSB_INSTANCE",
        "SET_ASIC_INSTANCE",
        "SET_USDC_INSTANCE",
        "SET_HEX_INSTANCE",
        "SET_CHAINLINK_INSTANCE",
        "SET_STAKING_INSTANCE",
        "SET_WAATCA_INSTANCE",
        "SET_NFT_REWARDS_INSTANCE",
        "SET_NFT_HEX_REWARDS_INSTANCE",
        "SET_BURN_INSTANCE",
        "SET_ASIC_MINER_INSTANCE",
        "SET_HEX_STAKER_INSTANCE",
        "SET_CARN_FAUCET_INSTANCE",
        "SET_PLSD_FAUCET_INSTANCE",
        "SET_PLSB_FAUCET_INSTANCE",
        "SET_ASIC_FAUCET_INSTANCE",
        "SET_USDC_FAUCET_INSTANCE",
        "SET_HEX_FAUCET_INSTANCE",
        "SET_TOKEN_INSTANCE",
        "SET_CONTRACT_INSTANCE",
      ]),
    };
  },
  methods: {
    async addToken(symbol) {
      let data = {};
      switch (symbol) {
        case "CARN":
          data = {
            address: this.CARN_ADDRESS,
            symbol: "CARN",
            decimals: "12",
          };
          break;
        case "PLSD":
          data = {
            address: this.PLSD_ADDRESS,
            symbol: "PLSD",
            decimals: "12",
          };
          break;
        case "PLSB":
          data = {
            address: this.PLSB_ADDRESS,
            symbol: "PLSB",
            decimals: "12",
          };
          break;
        case "ASIC":
          data = {
            address: this.ASIC_ADDRESS,
            symbol: "ASIC",
            decimals: "12",
          };
          break;
        case "USDC":
          data = {
            address: this.USDC_ADDRESS,
            symbol: "USDC",
            decimals: "6",
          };
          break;
        case "HEX":
          data = {
            address: this.HEX_ADDRESS,
            symbol: "HEX",
            decimals: "8",
          };
          break;
      }
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: data.address,
            symbol: data.symbol,
            decimals: data.decimals,
          },
        },
      });
    },
    secondstoDays(sec) {
      if (sec > 0) {
        var seconds = parseInt(sec, 10);

        var days = Math.floor(seconds / (3600 * 24)) + 1;
        if (days > 1) return `${days} Days`;
        else return `${days} Day`;
      } else {
        return "Few Seconds";
      }
    },

    addrTruncation(addr) {
      return (
        addr.slice(0, 6) +
        ". . . . ." +
        addr.slice(addr.length - 6, addr.length)
      );
    },
    sixFormat(number, decimal = 0) {
      if (this.getWeb3 && number)
        return parseFloat(Number(
          this.getWeb3.utils.fromWei(number.toString(), "Mwei")
        ).toFixed(decimal)).toString();
      else return 0;
    },
    eightFormat(number, decimal = 0) {
      if (this.getWeb3 && number) return parseFloat(Number(number / 1e8).toFixed(decimal)).toString();
      else return 0;
    },
    twelveFormat(number, decimal = 0) {
      if (this.getWeb3 && number)
        return parseFloat(Number(
          this.getWeb3.utils.fromWei(number.toString(), "szabo")
        ).toFixed(decimal)).toString();
      else return 0;
    },
    eighteenFormat(number, decimal = 0) {
      if (this.getWeb3 && number)
        return parseFloat(Number(
          this.getWeb3.utils.fromWei(number.toString(), "ether")
        ).toFixed(decimal)).toString();
      else return 0;
    },
    generalFormat(number, type, decimal = 0) {
      if (this.getWeb3 && number) {
        if (type === 'six') {
          return Number(
            this.getWeb3.utils.fromWei(number.toString(), "Mwei")
          ).toFixed(decimal);
        }
        else if (type === 'eight') {
          return Number(number / 1e8).toFixed(decimal);
        }
        else {
          return Number(
            this.getWeb3.utils.fromWei(number.toString(), "szabo")
          ).toFixed(decimal);
        }
      }
      else {
        return 0;
      }
    },
    sixToWei(number) {
      if (this.getWeb3 && number)
        return this.getWeb3.utils.toWei(number.toString(), "Mwei");
      else return 0;
    },
    eightToWei(number) {
      if (this.getWeb3 && number) return number * 1e8;
      else return 0;
    },
    twelveToWei(number) {
      if (this.getWeb3 && number)
        return this.getWeb3.utils.toWei(number.toString(), "szabo");
      else return 0;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    HHMMSS(totalseconds) {
      var day = 86400;
      // var hour = 3600;
      // var minute = 60;
      var daysout = Math.floor(totalseconds / day);
      // var hoursout = Math.floor((totalseconds - daysout * day) / hour);
      // var minutesout = Math.floor(
      //   (totalseconds - daysout * day - hoursout * hour) / minute
      // );
      // hoursout = hoursout.toString().length < 2 ? "0" + hoursout : hoursout;
      // minutesout = minutesout.toString().length < 2 ? "0" + minutesout : minutesout;

      return `${daysout} Days`;
    },
    humanDate(seconds) {
      return new Date(seconds * 1000).toLocaleString("en-US");
    },
    async fetchNFTs() {
      let baseURL = `${this.ALCHEMY_URL_BASE}${this.ALCHEMY_API_KEY}/getNFTs/`;

      let fetchURL = `${baseURL}?owner=${this.getUserAddress}&contractAddresses%5B%5D=${this.CONTRACT_ADDRESS}`;

      let data = await fetch(fetchURL).then((data) => data.json());

      if (data && data.ownedNfts) {
        return data.ownedNfts;
      } else {
        return [];
      }
    },

    formator(number, decimal = 2) {
      if (this.getWeb3 && number)
        return Number(
          this.getWeb3.utils.fromWei(number.toString(), this.UNIT_CONVERSION)
        ).toFixed(decimal);
      else return 0;
    },

    isPlural: function (number) {
      if (number == 1) {
        return "day"

      } else {
        return "days"
      }
    },
  },
  computed: {
    ...mapGetters("wallet", [
      "isLoading",
      "getWeb3",
      "walletChainID",
      "getUserAddress",
      "getCARNInstance",
      "getPLSDInstance",
      "getPLSBInstance",
      "getASICInstance",
      "getUSDCInstance",
      "getHEXInstance",
      "getCHAINLINKInstance",
      "getStakingInstance",
      "getWAATCAInstance",
      "getNftRewardsInstance",
      "getNftHexRewardsInstance",
      "getBurnInstance",
      "getASICMinerInstance",
      "getHEXStakerInstance",
      "getCARNFaucetInstance",
      "getPLSDFaucetInstance",
      "getPLSBFaucetInstance",
      "getASICFaucetInstance",
      "getUSDCFaucetInstance",
      "getHEXFaucetInstance",
      "getTokenInstance",
      "getContractInstance",
    ]),
  },

};
