const getters = {
  isLoading: (state) => {
    return state.loading;
  },
  getWeb3: (state) => {
    return state.web3;
  },
  walletChainID: (state) => {
    return state.walletChainID;
  },
  getUserAddress: (state) => {
    return state.userAddress;
  },
  getCARNInstance: (state) => {
    return state.CARNInstance;
  },
  getPLSDInstance: (state) => {
    return state.PLSDInstance;
  },
  getPLSBInstance: (state) => {
    return state.PLSBInstance;
  },
  getASICInstance: (state) => {
    return state.ASICInstance;
  },
  getUSDCInstance: (state) => {
    return state.USDCInstance;
  },
  getHEXInstance: (state) => {
    return state.HEXInstance;
  },
  getCHAINLINKInstance: (state) => {
    return state.CHAINLINKInstance;
  },
  getStakingInstance: (state) => {
    return state.stakingInstance;
  },
  getWAATCAInstance: (state) => {
    return state.WAATCAInstance;
  },
  getNftRewardsInstance: (state) => {
    return state.nftRewardsInstance;
  },
  getNftHexRewardsInstance: (state) => {
    return state.nftHexRewardsInstance;
  },
  getBurnInstance: (state) => {
    return state.burnInstance;
  },
  getASICMinerInstance: (state) => {
    return state.ASICMinerInstance;
  },
  getHEXStakerInstance: (state) => {
    return state.HEXStakerInstance;
  },

  getCARNFaucetInstance: (state) => {
    return state.CARNFaucetInstance;
  },
  getPLSDFaucetInstance: (state) => {
    return state.PLSDFaucetInstance;
  },
  getPLSBFaucetInstance: (state) => {
    return state.PLSBFaucetInstance;
  },
  getASICFaucetInstance: (state) => {
    return state.ASICFaucetInstance;
  },
  getUSDCFaucetInstance: (state) => {
    return state.USDCFaucetInstance;
  },
  getHEXFaucetInstance: (state) => {
    return state.HEXFaucetInstance;
  },
  getTokenInstance: (state) => {
    return state.tokenInstance;
  },
  getContractInstance: (state) => {
    return state.contractInstance;
  },


};

export default getters;
