<template>
  <v-row>
    <div class="col-12">
      <v-card class="pa-5">
        <h1>Disclaimer</h1>
        <v-divider class="my-2"></v-divider>

        <h2>Disclaimer: Risks of DeFi and Cryptocurrency</h2>
        <p>Cryptocurrency and Decentralized Finance (DeFi) are relatively new and complex technologies that come with a high degree of risk. Before investing in cryptocurrency or participating in DeFi projects, it's important to understand the risks involved. This disclaimer aims to highlight some of these risks, but it is not exhaustive.</p>
        <ol>
          <li>
            <strong>Volatility and market risk</strong>
            <p>Cryptocurrency markets are highly volatile and subject to significant price fluctuations. The value of your investment may increase or decrease rapidly, and there is no guarantee that you will make a profit. You should never invest more than you can afford to lose.</p>
          </li>
          <li>
            <strong>Regulatory risk</strong>
            <p>The regulatory environment surrounding cryptocurrency and DeFi is constantly evolving, and it is possible that new laws and regulations may be introduced that could impact the value of your investment. Regulatory changes could result in restrictions on cryptocurrency or even an outright ban, which could have a significant impact on the market.</p>
          </li>
          <li>
            <strong>Smart contract risk</strong>
            <p>DeFi platforms rely on smart contracts, which are self-executing contracts with the terms of the agreement between buyer and seller being directly written into lines of code. These contracts are complex and could contain errors or vulnerabilities that could be exploited by bad actors, resulting in the loss of your investment.</p>
          </li>
          <li>
            <strong>Cybersecurity risk</strong>
            <p>Cryptocurrency and DeFi platforms are vulnerable to cyber attacks, and hackers have successfully stolen millions of dollars from these platforms. You should take steps to secure your cryptocurrency and DeFi investments, such as using a hardware wallet, enabling two-factor authentication, and never typing your seed phrase anywhere.</p>
          </li>
          <li>
            <strong>Liquidity risk</strong>
            <p>Some DeFi projects may have low liquidity, meaning that it may be difficult to buy or sell tokens on the open market. In some cases, it may be impossible to sell your tokens at a fair price, or you may have to wait for an extended period to find a buyer. Never expect anyone to provide liquidity.</p>
          </li>
          <li>
            <strong>Penalty risk</strong>
            <p>It's important to note that our project has a very detailed white paper that outlines all the rules for participation. We strongly advise that you read this white paper carefully as certain parts contain penalties for not following the rules. By participating in our project, you acknowledge that you have read and understood the white paper and agree to abide by its rules and guidelines.</p>
          </li>
          <li>
            <strong>Expectations risk</strong>
            <p>Its important that you should NEVER have expectations of profit from the work of others in any capacity, whatsoever. The Carnival is a 100% decentralized and finished product. Any developer may add things to the CARNival in the future if they want, but that will be at their own discretion.</p>
          </li>
        </ol>
        <p>In conclusion, investing in cryptocurrency and participating in DeFi projects comes with significant risks. Before investing, you should thoroughly research the project, understand the technology, and be aware of the risks involved. You should also seek advice from a professional financial advisor to help you assess the risks and make an informed decision about your investment.</p>

      </v-card>
    </div>
  </v-row>
</template>
<script>
export default {
  name: "Disclaimer",
};
</script>
