const state = () => ({
  loading: false,
  web3: null,
  walletChainID: null,
  userAddress: null,
  CARNInstance: null,
  PLSDInstance: null,
  PLSBInstance: null,
  ASICInstance: null,
  USDCInstance: null,
  stakingInstance: null,
  WAATCAInstance: null,
  nftRewardsInstance: null,
  burnInstance: null,
  ASICMinerInstance: null,
  HEXStakerInstance: null,
  tokenInstance: null,
  contractInstance: null,
});
export default state;
