const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  
  SET_WALLETCHAINID: (state, payload) => {
    state.walletChainID = payload;
  },
  SET_WEB3: (state, payload) => {
    state.web3 = payload;
  },
  SET_USER_ADDRESS: (state, payload) => {
    state.userAddress = payload;
  },
  SET_CARN_INSTANCE: (state, payload) => {
    state.CARNInstance = payload;
  },
  SET_PLSD_INSTANCE: (state, payload) => {
    state.PLSDInstance = payload;
  },
  SET_PLSB_INSTANCE: (state, payload) => {
    state.PLSBInstance = payload;
  },
  SET_ASIC_INSTANCE: (state, payload) => {
    state.ASICInstance = payload;
  },

  SET_USDC_INSTANCE: (state, payload) => {
    state.USDCInstance = payload;
  },
  SET_HEX_INSTANCE: (state, payload) => {
    state.HEXInstance = payload;
  },
  SET_CHAINLINK_INSTANCE: (state, payload) => {
    state.CHAINLINKInstance = payload;
  },
  SET_STAKING_INSTANCE: (state, payload) => {
    state.stakingInstance = payload;
  },
  SET_WAATCA_INSTANCE: (state, payload) => {
    state.WAATCAInstance = payload;
  },
  SET_NFT_REWARDS_INSTANCE: (state, payload) => {
    state.nftRewardsInstance = payload;
  },
  SET_NFT_HEX_REWARDS_INSTANCE: (state, payload) => {
    state.nftHexRewardsInstance = payload;
  },
  SET_BURN_INSTANCE: (state, payload) => {
    state.burnInstance = payload;
  },
  SET_ASIC_MINER_INSTANCE: (state, payload) => {
    state.ASICMinerInstance = payload;
  },
  SET_HEX_STAKER_INSTANCE: (state, payload) => {
    state.HEXStakerInstance = payload;
  },

  SET_CARN_FAUCET_INSTANCE: (state, payload) => {
    state.CARNFaucetInstance = payload;
  },
  SET_PLSD_FAUCET_INSTANCE: (state, payload) => {
    state.PLSDFaucetInstance = payload;
  },
  SET_PLSB_FAUCET_INSTANCE: (state, payload) => {
    state.PLSBFaucetInstance = payload;
  },
  SET_ASIC_FAUCET_INSTANCE: (state, payload) => {
    state.ASICFaucetInstance = payload;
  },

  SET_USDC_FAUCET_INSTANCE: (state, payload) => {
    state.USDCFaucetInstance = payload;
  },
  SET_HEX_FAUCET_INSTANCE: (state, payload) => {
    state.HEXFaucetInstance = payload;
  },
  SET_TOKEN_INSTANCE: (state, payload) => {
    state.tokenInstance = payload;
  },
  SET_CONTRACT_INSTANCE: (state, payload) => {
    state.contractInstance = payload;
  },


};

export default mutations;
