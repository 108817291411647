<template>
  <div>
    <v-img src="@/assets/white_paper_images2/1.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/2.png"></v-img>
    <v-divider class="my-5"></v-divider>
<!--    <v-img src="@/assets/white_paper_images2/flow_chart2.png"></v-img>-->
<!--    <v-divider class="my-5"></v-divider>-->

    <v-img src="@/assets/white_paper_images2/3.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/4.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/5.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/6.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/7.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/8.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/9.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/10.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/11.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/12.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/13.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/14.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/15.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/16.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/17.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/18.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/19.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/20.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/21.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/22.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/23.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/24.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/25.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/26.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/27.png"></v-img>
    <v-divider class="my-5"></v-divider>
    <v-img src="@/assets/white_paper_images2/28.png"></v-img>
    <v-divider class="my-5"></v-divider>
  </div>
</template>
<script>
export default {
  name: "WhitePaper",
};
</script>
<style>
.v-main.WhitePaper .v-main__wrap> div {
  padding: 0 !important;
}
</style>
