<template>
  <div>
    <v-row>
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" @click="addToken('ASIC')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="purple darken-2">
              <img width="50px" src="@/assets/asiclogo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(ASICBalance) }}
                $ASIC
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" @click="addToken('PLSB')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="blue">
              <img width="50px" src="@/assets/plsblogo-modified.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(PLSBBalance) }}
                $PLSB
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="75" color="green">
              <img width="50px" src="@/assets/carn_logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(CARNBalance) }}
                $CARN
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>
    <v-row v-show="getUserAddress" class="justify-center">
      <div class="col-12 col-sm-6 col-md-5">
        <v-card class="mx-auto text-center">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="demo">0 Days 0 Hours 0 Minutes 0 Seconds</h3>
              </v-list-item-title>
              <h4 class="font-weight-regular">until the {{ text }} is Over.</h4>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>



    <v-row>

      <div class="col-12 col-md-6">
        <v-card
          :class="this.state === 0 ? '' : 'bg-grey'"
          class="pa-3 pa-sm-5"
          style="height: 100%"
        >
          <h1 class="grey--text text--darken-2">5 Day Reload Period</h1>
          <h4 class="font-weight-regular">
            5 days to withdraw your share of the mined PulseBitcoin and then
            deposit more ASIC for the next mining session
          </h4>
          <v-divider class="my-5"></v-divider>
          <v-card outlined class="pa-5 my-5">
            <h3 class="font-weight-regular">
              Withdraw your share of the mined PulseBitcoin from the last mining
              session
            </h3>

            <v-btn
              @click="onClaimReward"
              block
              x-large
              color="yellow"
              class="py-5 mt-5"
              :disabled="
                !getUserAddress || isLoading || Number(asicDeposits.sessionId) === currentSessionId || !Number(asicDeposits.amount)
              "
            >
              Withdraw PulseBitcoin
            </v-btn>
          </v-card>
          <v-card outlined class="pa-5 my-5">
            <v-text-field
              outlined
              type="text"
              v-model="ASICDepositAmount"
              label="Enter ASIC Deposit Amount"
            >
              <template v-slot:append>
                <v-btn
                  text
                  color="primary"
                  @click="ASICDepositAmount = twelveFormat(ASICBalance,12)"
                  small
                >
                  MAX
                </v-btn>
              </template>
            </v-text-field>
            <v-btn
              @click="onASICApprove()"
              block
              x-large
              color="yellow"
              class="py-5"
              :disabled="
                !getUserAddress || isLoading
              "
            >
              {{
                isASICApproved
                  ? isCARNApproved
                    ? "Deposit ASIC"
                    : "Approve CARN"
                  : "Approve ASIC"
              }}
            </v-btn>
            <small>10 CARN PER RIDE</small><br />
            <small>Note: You do NOT get your ASIC back, ever.</small><br />
            <small
              >Note: With this deposit, you will only get $PLSB for THIS mining session, NOT
              future sessions</small
            >
          </v-card>


        </v-card>
      </div>


      <div class="col-12 col-md-6">
        <v-card
          :class="this.state === 1 ? '' : 'bg-grey'"
          class="pa-3 pa-sm-5"
          style="height: 100%"
        >
          <h1 class="grey--text text--darken-2">30 Day ASIC Mining Session</h1>
          <h4 class="font-weight-regular">
            30 days period where the community's ASIC goes to work.
          </h4>
          <v-divider class="my-6"></v-divider>

          <h3 class="my-5">
            You Deposited: {{ (Number(asicDeposits.sessionId) > currentSessionId - 2)? twelveFormat(asicDeposits.amount) : 0}} $ASIC
          </h3><!--Number(asicDeposits.sessionId) === currentSessionId - 1 ? -->
          <h3 class="my-5" v-show="state===0">
            Total ASIC Deposited For Upcoming Session: {{ twelveFormat(totalAsicDepositForTheCurrentSession) }} $ASIC
          </h3>
          <h3 class="my-5">
            Total ASIC Deposited for {{(state===0)? "Previous":"This"}} Mining Session: {{Number(twelveFormat(totalAsicDepositForThePreviousSession)) }} $ASIC
          </h3>

          <h3 class="my-5" v-if="state===0 && Number(asicDeposits.sessionId) === currentSessionId"> <!-- reload period and they deposited already-->
            Upcoming Pool Ownership: {{ Number(totalAsicDepositForTheCurrentSession) ? (Number(asicDeposits.amount) / Number(totalAsicDepositForTheCurrentSession)).toFixed(2) * 100 : 0 }}%
          </h3>
          <h3 class="my-5" v-if="state===0 && Number(asicDeposits.sessionId) === currentSessionId-1"> <!-- reload period and they DIDNT deposit YET-->
            Previous Pool Ownership: {{ Number(totalAsicDepositForThePreviousSession) ?( Number(asicDeposits.amount) / Number(totalAsicDepositForThePreviousSession)).toFixed(2) * 100 : 0 }}%
          </h3>
          <h3 class="my-5" v-if="state===1 && Number(asicDeposits.sessionId) === currentSessionId - 1"> <!-- mining period and they DID deposit-->
            Pool Ownership: {{ Number(totalAsicDepositForThePreviousSession) ?( Number(asicDeposits.amount) / Number(totalAsicDepositForThePreviousSession)).toFixed(2) * 100 : 0 }}%
          </h3>

          <h3 class="my-5">
            Total ASIC {{(state===0)?"Held By":"Mined with"}} the Community Miner: {{ (state===0)? Number(twelveFormat(totalAsicHeldByTheAsicMinerAddress)): Number(twelveFormat(asicUsedToMine))   }} $ASIC
          </h3>
          <h3 class="my-5">
            Number of Deposits for {{(state===0)? "Upcoming Mining Session":"This Mining Session" }}: {{ numParticipantsForThisSession }} Deposits
          </h3>
          <h3 class="my-5">
            Number of Deposits in ALL sessions: {{ numTotalDepositsForAllSessions }} Deposits
          </h3>
          <h3 class="my-5">
            Total PLSB {{(state===0)? "Mined in Previous Mining Session":"Being Mined In This Session" }} + Unclaimed $PLSB: {{ twelveFormat(totalPLSBRewards) }} $PLSB
          </h3>
          <h3 class="my-5" v-if="state===0">
            Total Unclaimed Rewards: {{ twelveFormat(unclaimedRewards) }}
          </h3>




        </v-card>
      </div>


    </v-row>





    <v-row>
      <div class="col-12 col-sm-6 col-md-6">
        <v-card class="mx-auto" >
          <v-btn
              @click="onStartReloadPeriod"
              block
              x-large
              color="secondary"
              class="mt-5 py-5"
              :disabled="
            !getUserAddress || isLoading || !showStartReloadPeriodButton
          "
          >
            End Mining Session
          </v-btn>
        </v-card>
      </div>
      <div class="col-12 col-sm-6 col-md-6">
        <v-card class="mx-auto">
          <v-btn
              @click="onStartMiningSession"
              block
              x-large
              color="secondary"
              class="mt-5 py-5"
              :disabled="
            !getUserAddress || isLoading || !showStartStakedPeriodButton
          "
          >
            Mine PulseBitcoin with {{ twelveFormat(totalAsicHeldByTheAsicMinerAddress) }} $ASIC Tokens
          </v-btn>
        </v-card>
      </div>
    </v-row>








    <v-row class="justify-center">
      <div class="col-12 col-md-6">
        <v-card outlined class="pa-5">
          <h1 class="grey--text text--darken-2">
            The Trapped ASIC Release Pool
          </h1>
          <h4 class="font-weight-regular">
            ALL the ASIC that has been collected over time will be trapped in
            the contract until this pool reaches 100K $CARN, at which point ALL
            the trapped ASIC will be released: 60% to PulseDogecoin Stakers and
            40% to the WAATCA POOL. The 100K CARN will all go to the BUY and BURN Contract.
          </h4>
          <v-divider class="my-5"></v-divider>
          <v-row>
            <div class="col-12">
              <label for=""
                >Amount of CARN Collected So Far:
                {{ twelveFormat(trappedAsicReleasePool) }}</label
              >
              <v-text-field
                v-model="CARNDepositAmount"
                outlined
                class="mt-3"
                label="CARN Amount"
                type="text"
              >
                <template v-slot:append>
                  <v-btn
                    text
                    color="primary"
                    @click="CARNDepositAmount = twelveFormat(CARNBalance,12)"
                    small
                  >
                    MAX
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                v-if="isCARNApproved"
                block
                x-large
                color="yellow"
                class="py-5"
                @click="onCARNDeposit"
                :disabled="!getUserAddress || isLoading"
              >
                {{
                  Number(CARNDepositAmount) + Number(twelveFormat(trappedAsicReleasePool)) >=
                  100000
                    ? "DEPOSIT CARN AND RELEASE ALL TRAPPED ASIC"
                    : "Deposit CARN"
                }}
              </v-btn>
              <v-btn
                v-else
                block
                x-large
                color="yellow"
                class="py-5"
                @click="onCARNApprove"
                :disabled="!getUserAddress || isLoading"
              >
                Approve CARN
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </div>
    </v-row>
    <v-row>
      <div class="col-12">
        <v-card class="pa-5">
          <h1>Community ASIC Miner Instructions</h1>
          <v-divider class="my-2"></v-divider>
          <ol>
            <li>
              <strong> Approve the smart contract to interact with your ASIC and CARN</strong>
            </li>
            <li>
              <strong> During a Reload Period, Choose how much ASIC you want to deposit into the community Miner and enter that number into the "Enter ASIC Deposit Amount" field, then click "Deposit ASIC". To participate, you must deposit at least 25 ASIC.</strong>
            </li>
            <li>
              <strong> Upon depositing ASIC, 10 CARN will be withdrawn from your wallet also, so make sure to have at least 10 CARN in you wallet before staking</strong>
            </li>
            <li>
              <strong>You can deposit ASIC multiple times from the same wallet. So if you deposit a certain amount of ASIC, then you can deposit more after that from the same wallet.</strong>
            </li>
            <li>
              <strong> A normal reload period is 5 days, but the initial reload period will be 30 days</strong>
            </li>
            <li>
              <strong> Once the Reload Period Ends, anyone can click the "Mine PulseBitcoin" button, and like wise once the Mining Session ends, anyone can click the "End Mining Session" button</strong>
            </li>
            <li>
              <strong> Once a Mining Session ends, and the 5 day reload period begins, you must withdraw your PLSB rewards in that 10 days or you lose it all and it will be distributed to the next set of Community Miners in the next mining session</strong>
            </li>
            <li>
              <strong> Your PLSB reward is a percentage of the total PLSB Mined during current session with all the ASIC in the Community Miner. That percentage is the ASIC you deposited in this session only divided by the total ASIC deposited for this session only.</strong>
            </li>
            <li>
              <strong>Example: At the start of the 10th reload period (~10 months after launch of the Carnival) lets say there is 1,000,000 ASIC in the Community Miner that has built up over the last 10 months. Then during that 10th reload period ONLY 2 people deposit 25 ASIC into the Community Miner.
              Then those 2 people will split the total PLSB mined with that 1,000,050 (around ~75K PLSB). All people who deposited ASIC in the earlier mining session will get a piece of that ~75K PLSB</strong>
            </li>
            <li>
              <strong> After you withdraw your PLSB reward, you can deposit more ASIC for the next 30 day Mining Session if you want.</strong>
            </li>
            <li>
              <strong> All the ASIC deposited into the Community Miner is trapped inside the smart contract until "The Trapped ASIC Release Pool" reaches 100K CARN, at which point all trapped ASIC gets released (60% to the PLSD stakers, 40% to the WAATCA NFT Pool) </strong>
            </li>
            <li>
              <strong> That 100K CARN all gets sent to the Buy and Burn contract</strong>
            </li>

          </ol>
        </v-card>
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ASICMiner",
  data() {
    return {
      text: "Reload",
      clearIntervalX: null,


      ASICBalance: 0,
      CARNBalance: 0,
      PLSBBalance: 0,

      isASICApproved: false,
      isCARNApproved: false,

      ASICDepositAmount: 0,
      CARNDepositAmount: 0,

      state: 0,
      currentSessionId: 0,
      asicDeposits: 0,
      nextReloadTime: 0,
      nextMiningStartTime: 0,

      unclaimedRewards: 0,
      totalPLSBRewards: 0,
      trappedAsicReleasePool: 0,
      totalAsicDepositForTheCurrentSession: 0,
      totalAsicDepositForThePreviousSession: 0,
      totalAsicHeldByTheAsicMinerAddress: 0,
      numTotalDepositsForAllSessions: 0,
      numParticipantsForThisSession: 0,
      asicUsedToMine: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalX);
  },
  async mounted() {
    if (this.getUserAddress) this.readValues();
  },
  methods: {
    readValues() {
      let address = this.getUserAddress;
      Promise.all([
        this.getASICInstance.methods
          .allowance(address, this.ASIC_MINER_ADDRESS)
          .call(),
        this.getCARNInstance.methods
          .allowance(address, this.ASIC_MINER_ADDRESS)
          .call(),
        this.getASICInstance.methods.balanceOf(address).call(),
        this.getCARNInstance.methods.balanceOf(address).call(),
        this.getPLSBInstance.methods.balanceOf(address).call(),
        this.getASICMinerInstance.methods.state().call(),
        this.getASICMinerInstance.methods.currentSessionId().call(),
        this.getASICMinerInstance.methods.asicDeposits(address).call(),
        this.getASICMinerInstance.methods.nextReloadTime().call(),
        this.getASICMinerInstance.methods.nextMiningStartTime().call(),
        this.getASICMinerInstance.methods.unclaimedRewards().call(),
        this.getASICMinerInstance.methods.totalPLSBRewards().call(),
        this.getASICMinerInstance.methods.trappedAsicReleasePool().call(),
        this.getASICMinerInstance.methods.totalAsicDepositForTheCurrentSession().call(),
        this.getASICMinerInstance.methods.totalAsicDepositForThePreviousSession().call(),
        this.getASICInstance.methods.balanceOf(this.ASIC_MINER_ADDRESS).call(),
        this.getASICMinerInstance.methods.asicUsedToMine().call(),
        this.getASICMinerInstance.methods.numTotalDepositsForAllSessions().call(),
        this.getASICMinerInstance.methods.numParticipantsForThisSession().call()
      ]).then(
        async ([
          isASICApproved,
          isCARNApproved,
          ASICBalance,
          CARNBalance,
          PLSBBalance,
          state,
          currentSessionId,
          asicDeposits,
          nextReloadTime,
          nextMiningStartTime,
          unclaimedRewards,
          totalPLSBRewards,
          trappedAsicReleasePool,
          totalAsicDepositForTheCurrentSession,
          totalAsicDepositForThePreviousSession,
          totalAsicHeldByTheAsicMinerAddress,
          asicUsedToMine,
          numTotalDepositsForAllSessions,
          numParticipantsForThisSession
        ]) => {
          console.log("isASICApproved:", !!Number(isASICApproved));
          console.log("isCARNApproved:", !!Number(isCARNApproved));
          console.log("ASICBalance:", this.twelveFormat(ASICBalance));
          console.log("CARNBalance:", this.twelveFormat(CARNBalance));
          console.log("PLSBBalance:", this.twelveFormat(PLSBBalance));
          console.log("state:", state);
          console.log("currentSessionId:", currentSessionId);
          console.log("asicDeposits:", asicDeposits);
          console.log("nextReloadTime:", nextReloadTime);
          console.log("nextMiningStartTime:", nextMiningStartTime);
          console.log("unclaimedRewards:", unclaimedRewards);
          console.log("totalPLSBRewards:", totalPLSBRewards);
          console.log("trappedAsicReleasePool:", trappedAsicReleasePool);
          console.log("totalAsicDepositForTheCurrentSession:", totalAsicDepositForTheCurrentSession);
          console.log("totalAsicDepositForThePreviousSession:", totalAsicDepositForThePreviousSession);
          console.log("totalAsicHeldByTheAsicMinerAddress: ", totalAsicHeldByTheAsicMinerAddress)
          console.log("asicUsedToMine:", asicUsedToMine);
          console.log("numTotalDepositsForAllSessions:", numTotalDepositsForAllSessions);
          console.log("numParticipantsForThisSession: ", numParticipantsForThisSession)
          this.isASICApproved = Number(isASICApproved) > 0 ? true : false;
          this.isCARNApproved = Number(isCARNApproved) > 0 ? true : false;
          this.ASICBalance = ASICBalance;
          this.CARNBalance = CARNBalance;
          this.PLSBBalance = PLSBBalance;
          this.state = Number(state);
          this.currentSessionId = Number(currentSessionId);
          this.asicDeposits = asicDeposits;
          this.nextReloadTime = Number(nextReloadTime);
          this.nextMiningStartTime = Number(nextMiningStartTime);
          this.unclaimedRewards = unclaimedRewards;
          this.totalPLSBRewards = totalPLSBRewards;
          this.trappedAsicReleasePool = trappedAsicReleasePool;
          this.totalAsicDepositForTheCurrentSession = totalAsicDepositForTheCurrentSession;
          this.totalAsicDepositForThePreviousSession = totalAsicDepositForThePreviousSession;
          this.totalAsicHeldByTheAsicMinerAddress = totalAsicHeldByTheAsicMinerAddress;


          this.asicUsedToMine = asicUsedToMine;
          this.numTotalDepositsForAllSessions = numTotalDepositsForAllSessions;
          this.numParticipantsForThisSession = numParticipantsForThisSession;
          // this.totalStaked = await this.getASICMinerInstance.methods
          //   .stakePool(this.currentSessionId - 1)
          //   .call();
          // console.log("totalStaked:", this.totalStaked);
          clearInterval(this.clearIntervalX);
          this.counter();
        }
      );
    },

    counter() {
      let countDownDate = 0;

      if (this.state === 0) {
        this.text = "Reload Period";
        countDownDate = this.nextMiningStartTime * 1000;
        console.log("here state is 0");
      } else if (this.state === 1) {
        this.text = "Mining Session";
        countDownDate = this.nextReloadTime * 1000;
        console.log("here state is 1");
      }

      this.clearIntervalX = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalX);
          document.getElementById(
            "demo"
          ).innerHTML = `0 Days 0 Hours 0 Minutes 0 Seconds`;
        } else {
          document.getElementById(
            "demo"
          ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },

    onClaimReward() {
      this.SET_LOADING(true);
      this.getASICMinerInstance.methods
        .claimReward()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Claim Rewards transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Claim Rewards transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Claim Rewards transaction has Failed");
        });
    },

    // ASIC
    onASICApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }

      if (this.isASICApproved) {
        if (this.isCARNApproved) this.onASICDeposit();
        else this.onCARNApprove();
        return;
      }

      this.SET_LOADING(true);
      this.getASICInstance.methods
        .approve(this.ASIC_MINER_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("ASIC Approve transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("ASIC Approve transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("ASIC Approve transaction has Failed");
        });
    },

    onASICDeposit() {
      if (!this.ASICDepositAmount) {
        this.$toasted.show("Enter ASIC Deposit Amount");
        return;
      }
      if (Number(this.ASICDepositAmount) < 25) {
        this.$toasted.show(
          "You need at least 25 ASIC in your account to deposit"
        );
        return;
      }
      if (
        Number(this.ASICDepositAmount) >
        Number(this.twelveFormat(this.ASICBalance))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }
      if (Number(this.twelveFormat(this.CARNBalance)) < 10) {
        this.$toasted.show(
          "You need at least 10 CARN in your account to deposit"
        );
        return;
      }
      this.SET_LOADING(true);
      this.getASICMinerInstance.methods
        .deposit(this.twelveToWei(this.ASICDepositAmount))
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("ASIC Deposit transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.clearForm();
          this.SET_LOADING(false);
          this.$toasted.show("ASIC Deposit transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("ASIC Deposit transaction has Failed");
        });
    },

    onStartMiningSession() {
      this.SET_LOADING(true);
      this.getASICMinerInstance.methods
        .startMiningSession()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Start Mining Session transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Start Mining Session transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Start Mining Session transaction has Failed");
        });
    },

    onStartReloadPeriod() {
      this.SET_LOADING(true);
      this.getASICMinerInstance.methods
        .startReloadPeriod()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Start Reload Period transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Start Reload Period transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Start Reload Period transaction has Failed");
        });
    },

    // CARN
    onCARNApprove() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }

      this.SET_LOADING(true);
      this.getCARNInstance.methods
        .approve(this.ASIC_MINER_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("CARN Approve transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("CARN Approve transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("CARN Approve transaction has Failed");
        });
    },

    onCARNDeposit() {
      if (!this.CARNDepositAmount) {
        this.$toasted.show("Enter CARN Deposit Amount");
        return;
      }

      if (
        Number(this.CARNDepositAmount) >
        Number(this.twelveFormat(this.CARNBalance))
      ) {
        this.$toasted.show("Insufficient balance in your wallet.");
        return;
      }

      this.SET_LOADING(true);
      this.getASICMinerInstance.methods
        .depositCARNToTrappedPool(this.twelveToWei(this.CARNDepositAmount))
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("CARN Deposit transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.clearForm();
          this.SET_LOADING(false);
          this.$toasted.show("CARN Deposit transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("CARN Deposit transaction has Failed");
        });
    },

    clearForm() {
      this.ASICDepositAmount = 0;
      this.CARNDepositAmount = 0;
    },
  },
  computed: {
    showStartReloadPeriodButton() {
      //1676252220
      return (
        this.state === 1 && new Date().getTime() > this.nextReloadTime * 1000
      );
    },
    showStartStakedPeriodButton() {
      return (
        this.state === 0 &&
        new Date().getTime() > this.nextMiningStartTime * 1000
      );
    },
  },
  watch: {
    getUserAddress() {
        if (this.getUserAddress)
          this.readValues();
    },
  },
};
</script>
<style>
.v-main.CommunityCarnivalAsicMiner .v-main__wrap {
  background-image: url("../assets/hexlogos2.jpg");

  background-size: 200%;
  background-position: center;
}

</style>
