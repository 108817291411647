<template>
  <div style="height: 100%">
    <v-row class="justify-center">
      <div class="col-12 col-sm-4 col-md-2">
        <v-card class="mx-auto" @click="addToken('PLSD')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="cyan lighten-1">
              <img src="@/assets/plsdlogov2-modified.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(PLSDBalance) }} $PLSD
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-4 col-md-2">
        <v-card class="mx-auto" @click="addToken('PLSB')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="blue">
              <img src="@/assets/plsblogo-modified.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(PLSBBalance) }} $PLSB
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-4 col-md-2">
        <v-card class="mx-auto" @click="addToken('ASIC')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="purple darken-2">
              <img src="@/assets/asiclogo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(ASICBalance) }} $ASIC
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-4 col-md-2">
        <v-card class="mx-auto" @click="addToken('CARN')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="green">
              <img src="@/assets/carn_logo.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ twelveFormat(CARNBalance) }} $CARN
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-4 col-md-2">
        <v-card class="mx-auto" @click="addToken('USDC')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="green">
              <img src="@/assets/usdc.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ sixFormat(USDCBalance) }} $USDC
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="col-12 col-sm-4 col-md-2">
        <v-card class="mx-auto" @click="addToken('HEX')">
          <v-list-item two-line>
            <v-list-item-avatar size="55" color="green">
              <img src="@/assets/hex.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                {{ eightFormat(HEXBalance) }} $HEX
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </v-row>

    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="pa-5 text-center">
          <a :href="ETHERSCAN_URL_BASE + 'address/' + PLSB_FAUCET_ADDRESS" target="_blank" class="black--text">
          <h2 class="grey--text text--darken-2">PulseBitcoin Faucet</h2>
          </a>

          <h4 class="font-weight-regular">7 Free PulseBitcoin, once a week.</h4>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="increaseIntervalPLSB">0D 0H 0M 0S</h3>
              </v-list-item-title>
                  <h4 class="font-weight-regular">
                    until the next time you can withdraw.
                  </h4>
            </v-list-item-content>
          </v-list-item>
          Faucet Balance: {{ twelveFormat(PLSBFaucetBalance) }} $PLSB



          <v-divider class="my-5"></v-divider>

          <v-btn
            @click="withdrawPLSB()"
            block
            x-large
            color="primary"
            class="py-5"
            :disabled="
              !getUserAddress ||
              isLoading ||
              !Number(PLSBFaucetBalance) ||
              Number(CARNBalance) < 369e12 ||
              !hasAtLeastOneNonTransferableNFT() ||
              cantWithdrawPLSB()
            "
          >
            Withdraw 7 $PLSB
          </v-btn>
        </v-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="pa-5 text-center">
          <a :href="ETHERSCAN_URL_BASE + 'address/' + ASIC_FAUCET_ADDRESS" target="_blank" class="black--text">
          <h2 class="grey--text text--darken-2">ASIC Faucet</h2>
          </a>
          <h4 class="font-weight-regular">7 Free ASIC, once a week.</h4>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="increaseIntervalASIC">0D 0H 0M 0S</h3>
              </v-list-item-title>
                  <h4 class="font-weight-regular">
                    until the next time you can withdraw.
                  </h4>
            </v-list-item-content>
          </v-list-item>
          Faucet Balance: {{ twelveFormat(ASICFaucetBalance) }} $ASIC


          <v-divider class="my-5"></v-divider>

          <v-btn
            @click="withdrawASIC()"
            block
            x-large
            color="primary"
            class="py-5"
            :disabled="
              !getUserAddress ||
              isLoading ||
              !Number(ASICFaucetBalance) ||
              Number(CARNBalance) < 369e12 ||
              hasAtLeastOneNonTransferableNFT() ||
              cantWithdrawASIC()
            "
          >
            Withdraw 7 $ASIC
          </v-btn>
        </v-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="pa-5 text-center">
          <a :href="ETHERSCAN_URL_BASE + 'address/' + PLSD_FAUCET_ADDRESS" target="_blank" class="black--text">
          <h2 class="grey--text text--darken-2">PulseDogecoin Faucet</h2>
          </a>
          <h4 class="font-weight-regular">
            7 Free PulseDogecoin, once a week.
          </h4>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="increaseIntervalPLSD">0D 0H 0M 0S</h3>
              </v-list-item-title>
                  <h4 class="font-weight-regular">
                    until the next time you can withdraw.
                  </h4>
            </v-list-item-content>
          </v-list-item>
          Faucet Balance: {{ twelveFormat(PLSDFaucetBalance) }} $PLSD


          <v-divider class="my-5"></v-divider>

          <v-btn
            @click="withdrawPLSD()"
            block
            x-large
            color="primary"
            class="py-5"
            :disabled="
              !getUserAddress ||
              isLoading ||
              !Number(PLSDFaucetBalance) ||
              Number(CARNBalance) < 369e12 ||
              hasAtLeastOneNonTransferableNFT() ||
              cantWithdrawPLSD()
            "
          >
            Withdraw $PLSD
          </v-btn>
        </v-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="pa-5 text-center">
          <a :href="ETHERSCAN_URL_BASE + 'address/' + CARN_FAUCET_ADDRESS" target="_blank" class="black--text">
          <h2 class="grey--text text--darken-2">CARN Faucet</h2>
          </a>
          <h4 class="font-weight-regular">7 CARN Rewards, once a week.</h4>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="increaseIntervalCARN">0D 0H 0M 0S</h3>
              </v-list-item-title>
                  <h4 class="font-weight-regular">
                    until the next time you can withdraw.
                  </h4>
            </v-list-item-content>
          </v-list-item>
          Faucet Balance: {{ twelveFormat(CARNFaucetBalance) }} $CARN


          <v-divider class="my-5"></v-divider>

          <v-btn
            @click="withdrawCARN()"
            block
            x-large
            color="primary"
            class="py-5"
            :disabled="
              !getUserAddress ||
              isLoading ||
              !Number(CARNFaucetBalance) ||
              Number(CARNBalance) < 369e12 ||
              hasAtLeastOneNonTransferableNFT() ||
              cantWithdrawCARN()
            "
          >
            Withdraw 7 $CARN
          </v-btn>
        </v-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="pa-5 text-center">
          <a :href="ETHERSCAN_URL_BASE + 'address/' + HEX_FAUCET_ADDRESS" target="_blank" class="black--text">
          <h2 class="grey--text text--darken-2">HEX Faucet</h2>
          </a>
          <h4 class="font-weight-regular">7 HEX Rewards, once a week.</h4>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="increaseIntervalHEX">0D 0H 0M 0S</h3>
              </v-list-item-title>
                  <h4 class="font-weight-regular">
                    until the next time you can withdraw.
                  </h4>
            </v-list-item-content>
          </v-list-item>
          Faucet Balance: {{ twelveFormat(HEXFaucetBalance) }} $HEX


          <v-divider class="my-5"></v-divider>

          <v-btn
            @click="withdrawHEX()"
            block
            x-large
            color="primary"
            class="py-5"
            :disabled="
              !getUserAddress ||
              isLoading ||
              !Number(HEXFaucetBalance) ||
              Number(CARNBalance) < 369e12 ||
              hasAtLeastOneNonTransferableNFT() ||
              cantWithdrawHEX()
            "
          >
            Withdraw 7 $HEX
          </v-btn>
        </v-card>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <v-card class="pa-5 text-center">
          <a :href="ETHERSCAN_URL_BASE + 'address/' + USDC_FAUCET_ADDRESS" target="_blank" class="black--text">
          <h2 class="grey--text text--darken-2">USDC Faucet</h2>
          </a>
          <h4 class="font-weight-regular">7 Free USDC, once a week.</h4>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="increaseIntervalUSDC">0D 0H 0M 0S</h3>
              </v-list-item-title>
                  <h4 class="font-weight-regular">
                    until the next time you can withdraw.
                  </h4>
            </v-list-item-content>
          </v-list-item>
          Faucet Balance: {{ twelveFormat(USDCFaucetBalance) }} $USDC


          <v-divider class="my-5"></v-divider>

          <v-btn
            @click="withdrawUSDC()"
            block
            x-large
            color="primary"
            class="py-5"
            :disabled="
              !getUserAddress ||
              isLoading ||
              !Number(USDCFaucetBalance) ||
              Number(CARNBalance) < 369e12 ||
              hasAtLeastOneNonTransferableNFT() ||
              cantWithdrawUSDC()
            "
          >
            Withdraw $USDC
          </v-btn>
        </v-card>
      </div>
    </v-row>


    <v-row>
      <div class="col-12">
        <v-card class="pa-5">
          <h1>Faucet Instructions</h1>
          <v-divider class="my-2"></v-divider>
          <p>To prevent bots from taking advantage of these faucets, In order to withdraw from any of the above faucets you must have both of the following two things:</p>
          <ol>
            <li>
              <strong> Own at least one <a href="https://pulsebitcoinlock.app">PulseBitcoinLock</a> Non-Transferable NFT</strong>
            </li>
            <li>
              <strong> Own at least 369 CARN Tokens in your wallet</strong>
            </li>

          </ol>
          <br>
          <p>To be clear: If you dont have 369 CARN in your wallet or you do not have a Non-Transferable PulseBitcoinLock NFT in your wallet,
            then the WITHDRAW buttons above will be greyed out and the contract wont let you withdraw.</p>

        </v-card>
      </div>
    </v-row>

  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "PulseBitcoinFaucet",
  data() {
    return {
      nextRequestPLSB: 0,
      nextRequestASIC: 0,
      nextRequestPLSD: 0,
      nextRequestCARN: 0,
      nextRequestHEX: 0,
      nextRequestUSDC: 0,
      PLSBBalance: 0,
      ASICBalance: 0,
      PLSDBalance: 0,
      CARNBalance: 0,
      HEXBalance: 0,
      USDCBalance: 0,
      PLSBFaucetBalance: 0,
      ASICFaucetBalance: 0,
      PLSDFaucetBalance: 0,
      CARNFaucetBalance: 0,
      HEXFaucetBalance: 0,
      USDCFaucetBalance: 0,
      clearIntervalPLSB: null,
      clearIntervalASIC: null,
      clearIntervalPLSD: null,
      clearIntervalCARN: null,
      clearIntervalHEX: null,
      clearIntervalUSDC: null,
      nfts: [],
      totalNFTS: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalPLSB);
    clearInterval(this.clearIntervalASIC);
    clearInterval(this.clearIntervalPLSD);
    clearInterval(this.clearIntervalCARN);
    clearInterval(this.clearIntervalHEX);
    clearInterval(this.clearIntervalUSDC);
  },
  async mounted() {
    if (this.getUserAddress) this.readValues();
  },
  methods: {
    readValues() {
      let address = this.getUserAddress;
      Promise.all([
        this.getPLSBFaucetInstance.methods.nextRequestAt(address).call(),
        this.getASICFaucetInstance.methods.nextRequestAt(address).call(),
        this.getPLSDFaucetInstance.methods.nextRequestAt(address).call(),
        this.getCARNFaucetInstance.methods.nextRequestAt(address).call(),
        this.getHEXFaucetInstance.methods.nextRequestAt(address).call(),
        this.getUSDCFaucetInstance.methods.nextRequestAt(address).call(),
        this.getPLSBInstance.methods.balanceOf(address).call(),
        this.getASICInstance.methods.balanceOf(address).call(),
        this.getPLSDInstance.methods.balanceOf(address).call(),
        this.getCARNInstance.methods.balanceOf(address).call(),
        this.getHEXInstance.methods.balanceOf(address).call(),
        this.getUSDCInstance.methods.balanceOf(address).call(),
        this.getPLSBInstance.methods.balanceOf(this.PLSB_FAUCET_ADDRESS).call(),
        this.getASICInstance.methods.balanceOf(this.ASIC_FAUCET_ADDRESS).call(),
        this.getPLSDInstance.methods.balanceOf(this.PLSD_FAUCET_ADDRESS).call(),
        this.getCARNInstance.methods.balanceOf(this.CARN_FAUCET_ADDRESS).call(),
        this.getHEXInstance.methods.balanceOf(this.HEX_FAUCET_ADDRESS).call(),
        this.getUSDCInstance.methods.balanceOf(this.USDC_FAUCET_ADDRESS).call(),
      ]).then(
        ([
          nextRequestPLSB,
          nextRequestASIC,
          nextRequestPLSD,
          nextRequestCARN,
          nextRequestHEX,
          nextRequestUSDC,
          PLSBBalance,
          ASICBalance,
          PLSDBalance,
          CARNBalance,
          HEXBalance,
          USDCBalance,
          PLSBFaucetBalance,
          ASICFaucetBalance,
          PLSDFaucetBalance,
          CARNFaucetBalance,
          HEXFaucetBalance,
          USDCFaucetBalance,
        ]) => {
          this.nextRequestPLSB = nextRequestPLSB;
          this.nextRequestASIC = nextRequestASIC;
          this.nextRequestPLSD = nextRequestPLSD;
          this.nextRequestCARN = nextRequestCARN;
          this.nextRequestHEX = nextRequestHEX;
          this.nextRequestUSDC = nextRequestUSDC;
          this.PLSBBalance = PLSBBalance;
          this.ASICBalance = ASICBalance;
          this.PLSDBalance = PLSDBalance;
          this.CARNBalance = CARNBalance;
          this.HEXBalance = HEXBalance;
          this.USDCBalance = USDCBalance;
          this.PLSBFaucetBalance = PLSBFaucetBalance;
          this.ASICFaucetBalance = ASICFaucetBalance;
          this.PLSDFaucetBalance = PLSDFaucetBalance;
          this.CARNFaucetBalance = CARNFaucetBalance;
          this.HEXFaucetBalance = HEXFaucetBalance;
          this.USDCFaucetBalance = USDCFaucetBalance;

          console.log("nextRequestPLSB: ", nextRequestPLSB);
          console.log("nextRequestASIC: ", nextRequestASIC);
          console.log("nextRequestPLSD: ", nextRequestPLSD);
          console.log("nextRequestCARN: ", nextRequestCARN);
          console.log("nextRequestHEX: ", nextRequestHEX);
          console.log("nextRequestUSDC: ", nextRequestUSDC);
          console.log("PLSBBalance: ", PLSBBalance);
          console.log("ASICBalance: ", ASICBalance);
          console.log("PLSDBalance: ", PLSDBalance);
          console.log("CARNBalance: ", CARNBalance);
          console.log("HEXBalance: ", HEXBalance);
          console.log("USDCBalance: ", USDCBalance);
          console.log("PLSBFaucetBalance: ", PLSBFaucetBalance);
          console.log("ASICFaucetBalance: ", ASICFaucetBalance);
          console.log("PLSDFaucetBalance: ", PLSDFaucetBalance);
          console.log("CARNFaucetBalance: ", CARNFaucetBalance);
          console.log("HEXFaucetBalance: ", HEXFaucetBalance);
          console.log("USDCFaucetBalance: ", USDCFaucetBalance);

          clearInterval(this.clearIntervalPLSB);
          clearInterval(this.clearIntervalASIC);
          clearInterval(this.clearIntervalPLSD);
          clearInterval(this.clearIntervalCARN);
          clearInterval(this.clearIntervalHEX);
          clearInterval(this.clearIntervalUSDC);
          this.counterPLSB();
          this.counterASIC();
          this.counterPLSD();
          this.counterCARN();
          this.counterHEX();
          this.counterUSDC();

          //NFT's
          this.getUserNFTs();

        }
      );
    },

    cantWithdrawPLSB() {
      let countDownDate = this.nextRequestPLSB * 1000;
      let now = new Date().getTime();
      const distance = countDownDate - now;
      return distance > 0;
    },
    cantWithdrawASIC() {
      let countDownDate = this.nextRequestASIC * 1000;
      let now = new Date().getTime();
      const distance = countDownDate - now;
      return distance > 0;
    },
    cantWithdrawPLSD() {
      let countDownDate = this.nextRequestPLSD * 1000;
      let now = new Date().getTime();
      const distance = countDownDate - now;
      return distance > 0;
    },
    cantWithdrawCARN() {
      let countDownDate = this.nextRequestCARN * 1000;
      let now = new Date().getTime();
      const distance = countDownDate - now;
      return distance > 0;
    },
    cantWithdrawHEX() {
      let countDownDate = this.nextRequestHEX * 1000;
      let now = new Date().getTime();
      const distance = countDownDate - now;
      return distance > 0;
    },
    cantWithdrawUSDC() {
      let countDownDate = this.nextRequestUSDC * 1000;
      let now = new Date().getTime();
      const distance = countDownDate - now;
      return distance > 0;
    },

    counterPLSB() {
      let countDownDate = this.nextRequestPLSB * 1000;
      this.clearIntervalPLSB = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalPLSB);
          document.getElementById(
            "increaseIntervalPLSB"
          ).innerHTML = `0D 0H 0M 0S`;
        } else {
          document.getElementById(
            "increaseIntervalPLSB"
          ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },
    withdrawPLSB() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getPLSBFaucetInstance.methods
        .withdraw()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Withdrawing Free PLSB");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet completed");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet failed");
        });
    },

    counterASIC() {
      let countDownDate = this.nextRequestASIC * 1000;
      this.clearIntervalASIC = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalASIC);
          document.getElementById(
            "increaseIntervalASIC"
          ).innerHTML = `0D 0H 0M 0S`;
        } else {
          document.getElementById(
            "increaseIntervalASIC"
          ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },
    withdrawASIC() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getASICFaucetInstance.methods
        .withdraw()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Withdrawing Free ASIC");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet completed");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet failed");
        });
    },

    counterPLSD() {
      let countDownDate = this.nextRequestPLSD * 1000;
      this.clearIntervalPLSD = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalPLSD);
          document.getElementById(
            "increaseIntervalPLSD"
          ).innerHTML = `0D 0H 0M 0S`;
        } else {
          document.getElementById(
            "increaseIntervalPLSD"
          ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },
    withdrawPLSD() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getPLSDFaucetInstance.methods
        .withdraw()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Withdrawing Free PLSD");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet completed");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet failed");
        });
    },

    counterCARN() {
      let countDownDate = this.nextRequestCARN * 1000;
      this.clearIntervalCARN = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalCARN);
          document.getElementById(
            "increaseIntervalCARN"
          ).innerHTML = `0D 0H 0M 0S`;
        } else {
          document.getElementById(
            "increaseIntervalCARN"
          ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },
    withdrawCARN() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getCARNFaucetInstance.methods
        .withdraw()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Withdrawing CARN Rewards");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet completed");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet failed");
        });
    },

    counterHEX() {
      let countDownDate = this.nextRequestHEX * 1000;
      this.clearIntervalHEX = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalHEX);
          document.getElementById(
            "increaseIntervalHEX"
          ).innerHTML = `0D 0H 0M 0S`;
        } else {
          document.getElementById(
            "increaseIntervalHEX"
          ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },
    withdrawHEX() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getHEXFaucetInstance.methods
        .withdraw()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Withdrawing HEX Rewards");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet completed");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet failed");
        });
    },

    counterUSDC() {
      let countDownDate = this.nextRequestUSDC * 1000;
      this.clearIntervalUSDC = setInterval(function () {
        let now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalUSDC);
          document.getElementById(
            "increaseIntervalUSDC"
          ).innerHTML = `0D 0H 0M 0S`;
        } else {
          document.getElementById(
            "increaseIntervalUSDC"
          ).innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },
    withdrawUSDC() {
      if (!this.getUserAddress) {
        this.$toasted.show("Connect your wallet first!");
        return;
      }
      this.SET_LOADING(true);
      this.getUSDCFaucetInstance.methods
        .withdraw()
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Withdrawing Free USDC");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet completed");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw from faucet failed");
        });
    },
    getUserNFTs() {
      console.log("get user nfts")
      this.nfts = [];
      this.isNFTLoading = true;
      let URL = `https://api.thegraph.com/subgraphs/name/korkey128k/pulsebitcoinlocknft`; // use this for mainnet
      //let URL = `https://api.studio.thegraph.com/query/29707/pulsebitcoinlock/1.2.0`;

      const query = `
                    {
                      owner(id:"${this.getUserAddress.toLowerCase()}") {
                        id
                        nfts {
                          id
                          minter
                          lockedAmount
                          tipAmount
                          lockTime
                          unlockTime
                          uri
                          tokenId
                          timestamp
                          burner
                          burned
                          burnTime
                          transferable

                        }
                      }
                    }
                    `;
      const headers = {
        "Content-Type": "application/json",
      };
      console.log('hello');

      axios
          .post(URL, { query: query }, { headers: headers })
          .then((response) => {
            let data = response.data.data;
            let nfts = data.owner ? data.owner.nfts : [];
            if (nfts.length) {
              nfts.forEach(async (nft, i) => {
                let uri = this.geturi(nft.uri);
                axios
                    .get(uri)
                    .then((response) => {
                      nft.name = response.data.name;
                      nft.image = this.geturi(response.data.image);
                      Promise.all([
                        this.getNftRewardsInstance.methods
                            .tokenIdsToRegistered(nft.id)
                            .call(),
                        this.getNftRewardsInstance.methods
                            .tokenIdsToDailyRewardAmount(nft.id)
                            .call(),
                        this.getNftRewardsInstance.methods
                            .tokenIdsToLastWithdrawalDay(nft.id)
                            .call(),
                        this.getNftRewardsInstance.methods
                            .tokenIdsToEndRewardsDay(nft.id)
                            .call(),

                      ]).then(
                          ([isRegistered, reward, withdrawalDay, endRewardDay]) => {
                            console.log("isRegistered: ", isRegistered);
                            console.log("REWARD: ", reward);
                            console.log("WithdrawalDay: ", withdrawalDay);
                            nft.reward = reward;
                            nft.isRegistered = isRegistered;
                            nft.withdrawalDay = withdrawalDay;
                            nft.endRewardDay = endRewardDay;
                            this.nfts.push(nft);
                            if (i + 1 === nfts.length) {
                              this.nfts.sort(this.compareValues("id"));
                            }
                            this.isNFTLoading = false;
                          }
                      );
                    })
                    .catch((error) => {
                      console.log(error);
                      this.isNFTLoading = false;
                    });
              });
            } else {
              this.isNFTLoading = false;
            }

          })
          .catch((error) => {
            console.log(error);
            this.isNFTLoading = false;
          });
    },
    geturi(nfturi) {
      let arr = nfturi.split("/");
      let ch = arr[arr.length - 1];
      return `https://carn.infura-ipfs.io/ipfs/${ch}`;
    },
    hasAtLeastOneNonTransferableNFT() {
      console.log('inside: hasAtLeastOneNonTransferableNFT')
      console.log(this.nfts)
      for (let nft of this.nfts) {
        if (!nft.transferable) {
          return true;
        }
      }
      return false;



    }

  },
  watch: {
    getUserAddress() {
        if (this.getUserAddress)
          this.readValues();
    },
  },
};
</script>
<style>
.v-main.PulseBitcoinFaucet .v-main__wrap {
  background-image: url("../assets/plsblogo2.png");
  background-size: 100%;
  background-position: center;
}
</style>
