var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:_vm.isLoading ? 'loading' : '',attrs:{"id":"inspire"}},[(_vm.isLoading)?_c('div',{staticClass:"main-loader"},[_c('img',{attrs:{"src":require("@/assets/loader.webp")}})]):_vm._e(),_c('v-navigation-drawer',{attrs:{"width":300,"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item',[_c('img',{staticStyle:{"width":"100%","height":"100%","margin":"auto"},attrs:{"src":require("@/assets/ThePulseDogecoinStakingCarnivalTicketLogo2.jpg")}})]),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_vm._l((_vm.items),function(item,i){return [_c('v-list-item',{key:i + 'link',attrs:{"to":{ name: item.link },"exact":"","disabled":(item.text === 'Carnival Lotteries' && _vm.walletChainID == 369) ||
              (item.text === 'Carnival Lotteries' && _vm.walletChainID == 943) ||
              item.disable},on:{"click":function($event){item.text === 'Carnival Lotteries'
                ? (_vm.showSubLinks = !_vm.showSubLinks)
                : (_vm.showSubLinks = false)}}},[_c('v-list-item-content',[_c('img',{attrs:{"width":"45px","src":_vm.getImgUrl(item.icon)}})])],1),_vm._l((item.subLinks),function(sub,i){return [(_vm.showSubLinks)?[_c('v-list-item',{key:i + 'sublink',attrs:{"exact":"","to":{ name: sub.link },"disabled":sub.disable}},[_c('v-list-item-content',[_c('img',{attrs:{"width":"25px","src":_vm.getImgUrl(sub.icon)}})])],1)]:_vm._e()]})]}),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.walletChainID == 1 || _vm.walletChainID == 5
              ? 'https://app.uniswap.org/#/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0x488Db574C77dd27A07f9C97BAc673BC8E9fC6Bf3&chain=mainnet'
              : 'https://uniswap.hedron.pro/#/swap?outputCurrency=0x488Db574C77dd27A07f9C97BAc673BC8E9fC6Bf3',"target":"_blank"}},[_c('v-list-item',[_c('v-list-item-content',[_c('img',{attrs:{"width":"45px","src":_vm.getImgUrl('lit_carnuniswap.png')}})])],1)],1),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://twitter.com/KB_Dev_Official","target":"_blank"}},[_c('v-list-item',[_c('v-list-item-content',[_c('img',{attrs:{"width":"45px","src":_vm.getImgUrl('lit_twitter.png')}})])],1)],1),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://t.me/waatca","target":"_blank"}},[_c('v-list-item',[_c('v-list-item-content',[_c('img',{attrs:{"width":"45px","src":_vm.getImgUrl('lit_telegram.png')}})])],1)],1)],2)],1)],1),_c('v-app-bar',{attrs:{"app":""}},[_c('v-app-bar-nav-icon',{staticStyle:{"background-color":"#faf9f6"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-spacer'),(
        _vm.$route.name !== 'WhitePaper' &&
        _vm.$route.name !== 'Faq' &&
        _vm.$route.name !== 'PulseBitcoinFaucet' &&
        _vm.$route.name !== 'LotteriesHour' &&
        _vm.$route.name !== 'LotteriesWeek'
      )?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"}},[_c('a',{staticClass:"white--text",attrs:{"href":_vm.dynamicLink,"target":"_blank"}},[_vm._v(" Contract ")])]):_vm._e(),(_vm.getUserAddress)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":_vm.switchChain}},[_vm._v(" "+_vm._s(_vm.walletChainID == 1 ? "Switch to PulseChain" : _vm.walletChainID == 369 ? "Switch to Ethereum" : _vm.walletChainID == 5 ? "Switch to Testnet v4" : "Switch to Goerli Chain")+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","readonly":!!_vm.getUserAddress},on:{"click":_vm.onConnect}},[_vm._v(" "+_vm._s(_vm.getUserAddress ? _vm.addrTruncation(_vm.getUserAddress) : "Connect Wallet")+" ")])],1),_c('v-main',{class:_vm.$route.name},[_c('div',{staticClass:"pa-5 pa-sm-8",staticStyle:{"height":"100%"}},[_c('router-view')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }